import { WasmHandler } from "react-lib/frameworks/WasmController";
import Cookies from "js-cookie";

// Interface
import {
  State as MainState,
  SEGMENT_TYPE,
  commonDeletePatientSegment,
  commonGetMarketingPost,
  commonGetMarketingPromotion,
  commonListPatientSegmentList,
  commonListProxyPatient,
} from "../ManageInterface";

// APIs
import PatientSegmentNameList from "../../issara-sdk/apis/PatientSegmentNameList_apps_PHR";
import PatientSegmentSummaryList from "../../issara-sdk/apis/PatientSegmentSummaryList_apps_PHR";

export type State = {
  loadingMarketingSegment?: boolean;
  loadingMarketingPatient?: boolean;
  filterMarketingPatient?: {
    result: string;
    limit: number;
    offset: number;
    add_manually: boolean;
  };
  marketingSegmentList?: any;
  marketingPatientList?: any;
  marketingSegmentAgeGender?: any;
  marketingSegmentTopPost?: any[];
  marketingSegmentTopPromotion?: any[];
  marketingCreatePatient?: any;
};

export const StateInitial = {
  loadingMarketingSegment: false,
  loadingMarketingPatient: false,
  filterMarketingPatient: {
    result: "",
    limit: 10,
    offset: 0,
    add_manually: true,
  },
  marketingSegmentList: {},
  marketingPatientList: {},
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  marketingCreatePatient: null,
};

export type Event =
  // GET
  | { message: "handleGetMarketingSegmentList" }
  | { message: "handleGetMarketingPatientList" }
  | { message: "handleGetMarketingSummary" }
  | { message: "handleGetTopPost" }
  | { message: "handleGetTopPromotion" }
  | { message: "handleSearchMarketingPatient"; params: { hn: string } }
  // SET
  | {
      message: "handleSetMarketingPatientParams";
      params: { result: string; limit: number; offset: number };
    }
  // SAVE
  | {
      message: "handleCreateMarketingSegmentPatient";
      params: { result: string; hn_list: any[] };
    }
  | {
      message: "handleEditMarketingSegmentName";
      params: { result: string; old_result: string };
    }
  | { message: "handleDeleteMarketingSegment"; params: { result: string } }
  | { message: "handleDeleteMarketingPatient"; params: { id: number } };

type Picked = Pick<
  MainState,
  "selectedDivision" | "showSegment" | "divisionHasUser"
>;

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event>;

// GET
export const handleGetMarketingSegmentList: Handler = async (controller) => {
  controller.setState({ loadingMarketingSegment: true });
  const { showSegment, divisionHasUser } = controller.getState();

  const state = controller.getState();
  if (!state.selectedDivision?.division_code) {
    controller.setState({
      loadingMarketingSegment: false,
      errorMessage: null,
      successMessage: null,
      marketingSegmentList: {},
    } as any);
    return
  }

  const PatientSegmentLisApi = PatientSegmentNameList.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      limit: 999999,
      segment_type: SEGMENT_TYPE.MARKETING,
      segment_group: state.selectedDivision?.division_code,
    },
  });

  let promiseArray = [PatientSegmentLisApi];
  if (showSegment) {
    promiseArray.push(
      PatientSegmentNameList.get({
        apiToken: controller.apiToken || Cookies.get("apiToken"),
        params: {
          limit: 999999,
          segment_type: SEGMENT_TYPE.MARKETING,
        },
      })
    );
  }

  const [PatientSegmentRes, PatientSegmentAllRes] = await Promise.all(promiseArray);
  if (PatientSegmentRes?.[0] || PatientSegmentAllRes?.[0]) {
    const segmentNoneAddManually = (
      PatientSegmentAllRes?.[0]?.items || []
    ).filter((item: any) => !item.add_manually);

    let segmentList = [
      ...(segmentNoneAddManually || []),
      ...(PatientSegmentRes?.[0]?.items || []),
    ];

    if (!showSegment) {
      segmentList = (segmentList || []).filter((item: any) => item.add_manually);
    }

    controller.setState({
      loadingMarketingSegment: false,
      errorMessage: null,
      successMessage: null,
      marketingSegmentList: {items : segmentList},
    } as any);
  }
};

export const handleGetMarketingPatientList: Handler = async (controller) => {
  controller.setState({
    loadingMarketingPatient: true,
    marketingCreatePatient: null,
  } as any);

  const state = controller.getState();

  let reqParams: any = {
    result: state.filterMarketingPatient?.result,
    limit: state.filterMarketingPatient?.limit,
    offset: state.filterMarketingPatient?.offset,
    segment_type: SEGMENT_TYPE.MARKETING,
    have_tk_detail: "false",
  };

  if (state.filterMarketingPatient?.add_manually) {
    reqParams.segment_group = state.selectedDivision?.division_code;
  }
  const [error, response] = await commonListPatientSegmentList(
    controller as any,
    reqParams
  );
  if (error) {
    controller.setState({
      loadingMarketingPatient: false,
      errorMessage: error,
      successMessage: null,
      marketingPatientList: { next: null, previous: null, total: 0, items: [] },
    } as any);
  } else {
    controller.setState({
      loadingMarketingPatient: false,
      errorMessage: null,
      successMessage: null,
      marketingPatientList: response,
    } as any);
  }
};

export const handleGetMarketingSummary: Handler = async (controller) => {
  const state = controller.getState();

  if (state.filterMarketingPatient?.result === "") {
    return;
  }

  controller.setState({ loadingStatus: true } as any);

  let summaryParams: any = {
    segment_type: SEGMENT_TYPE.MARKETING,
    result: state.filterMarketingPatient?.result,
    group_by: "AGE_GENDER",
  };
  if (state.filterMarketingPatient?.add_manually) {
    summaryParams.segment_group = state.selectedDivision?.division_code;
  }

  const [response, error, network] = await PatientSegmentSummaryList.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: summaryParams,
  });

  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      marketingSegmentAgeGender: {},
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      marketingSegmentAgeGender: response,
    } as any);
    await handleGetTopPost(controller);
    await handleGetTopPromotion(controller);
  }
};

export const handleGetTopPost: Handler = async (controller) => {
  const state = controller.getState();

  const [error, response] = await commonGetMarketingPost(controller, {
    segmentName: state.filterMarketingPatient?.result,
  });

  if (error) {
    controller.setState({
      errorMessage: error,
      successMessage: null,
      marketingSegmentTopPost: [],
    } as any);
  } else {
    const patientList: any[] = state.marketingPatientList?.items?.map(
      (item: any) => item.proxy_patient
    );

    let result: any[] = [];
    let totalLikes: number = 0;
    response?.forEach((data: any) => {
      let segLikes: number = 0;
      data.likes?.forEach((like: any) => {
        if (patientList?.includes(like.patient)) {
          totalLikes += 1;
          segLikes += 1;
        }
      });
      result.push({ name: data.title, likes: segLikes });
    });

    result.sort((a: any, b: any) => (a.likes < b.likes ? 1 : -1));
    result = result.slice(0, 10);

    result.forEach((item: any) => {
      item.likes = ((item.likes * 100) / totalLikes).toFixed(2);
    });

    controller.setState({
      errorMessage: null,
      successMessage: null,
      marketingSegmentTopPost: result,
    } as any);
  }
};

export const handleGetTopPromotion: Handler = async (controller) => {
  const state = controller.getState();

  const [error, response] = await commonGetMarketingPromotion(controller, {
    segmentName: state.filterMarketingPatient?.result,
  });

  if (error) {
    controller.setState({
      errorMessage: error,
      successMessage: null,
      marketingSegmentTopPromotion: [],
    } as any);
  } else {
    const patientList: any[] = state.marketingPatientList?.items?.map(
      (item: any) => item.proxy_patient
    );

    let result: any[] = [];
    let totalLikes: number = 0;
    response?.forEach((data: any) => {
      let segLikes: number = 0;
      data.likes?.forEach((like: any) => {
        if (patientList?.includes(like.patient)) {
          totalLikes += 1;
          segLikes += 1;
        }
      });
      result.push({ name: data.title, likes: segLikes });
    });

    result.sort((a: any, b: any) => (a.likes < b.likes ? 1 : -1));
    result = result.slice(0, 10);

    result.forEach((item: any) => {
      item.likes = ((item.likes * 100) / totalLikes).toFixed(2);
    });

    controller.setState({
      errorMessage: null,
      successMessage: null,
      marketingSegmentTopPromotion: result,
    } as any);
  }
};

export const handleSearchMarketingPatient: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  const [error, response] = await commonListProxyPatient(
    controller as any,
    params // hn
  );

  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: error,
      successMessage: null,
      marketingCreatePatient: null,
    } as any);
  } else if (response?.items.length === 0) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
      marketingCreatePatient: null,
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      marketingCreatePatient: { ...response.items[0], hn: params.hn },
    } as any);
  }
};

// SET
export const handleSetMarketingPatientParams: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();
  controller.setState({
    filterMarketingPatient: { ...state.filterMarketingPatient, ...params },
  });
};

// SAVE
export const handleCreateMarketingSegmentPatient: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  if (params?.result && params?.hn_list?.length > 0) {
    const state = controller.getState();

    const [response, error, network] = await PatientSegmentNameList.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        ...params, // result, hn_list
        action: "CREATE",
        segment_type: SEGMENT_TYPE.MARKETING,
        segment_group: state.selectedDivision?.division_code,
      },
    });

    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Create Segment success.";
      handleGetMarketingSegmentList(controller);

      controller.handleEvent({
        message: "handleSetMarketingPatientParams",
        params: { result: params.result, offset: 0 },
      });
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "Can not create Segment.",
      successMessage: null,
    } as any);
  }
};

export const handleEditMarketingSegmentName: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  if (params?.result) {
    const state = controller.getState();

    const [response, error, network] = await PatientSegmentNameList.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        ...params, // result, old_result
        action: "EDIT",
        segment_type: SEGMENT_TYPE.MARKETING,
        segment_group: state.selectedDivision?.division_code,
      },
    });

    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Edit Segment success.";
      handleGetMarketingSegmentList(controller);
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "Segment name not found.",
      successMessage: null,
    } as any);
  }

  controller.setState({ loadingStatus: false } as any);
};

export const handleDeleteMarketingSegment: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  if (params?.result) {
    const [response, error, network] = await PatientSegmentNameList.post({
      apiToken: controller.apiToken || Cookies.get("apiToken"),
      data: {
        ...params, // result
        action: "DELETE",
        segment_type: SEGMENT_TYPE.MARKETING,
      },
    });

    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Delete Segment success.";
      handleGetMarketingSegmentList(controller);
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "Segment not found.",
      successMessage: null,
    } as any);
  }
};

export const handleDeleteMarketingPatient: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingStatus: true } as any);

  if (params?.id) {
    const [error, response] = await commonDeletePatientSegment(
      controller as any,
      {
        id: params.id,
      }
    );
    let successMessage: any = null;
    if (error) {
    } else {
      successMessage = "Delete Patient Segment success.";
      handleGetMarketingPatientList(controller);
    }

    controller.setState({
      loadingStatus: false,
      errorMessage: error ? error : null,
      successMessage: successMessage,
    } as any);
  } else {
    controller.setState({
      loadingStatus: false,
      errorMessage: "ID not found.",
      successMessage: null,
    } as any);
  }
};
