import React, { useCallback, useEffect, useMemo, useState } from "react";

// UX
import CardPayment, { PaymentDataType, PaymentType } from "./CardPayment";
import CardPaymentSkeleton from "./CardPaymentSkeleton";
import CardVerifyingPayment from "./CardVerifyingPayment";
// smartappointment
import ItemNotFound from "../smartappointment/ItemNotFound";
import * as Types from "./Types";
import moment from "moment";
import { useIntl } from "react-intl";
import {State} from "bplus-lib/MobPaymentInterface"

// Types
type CardPaymentListProps = {
  loading: boolean;
  checking?: boolean;
  paymentType: PaymentType;
  episodePaymentList?: PaymentDataType[];
  depositPaymentList?: Types.DepositPaymentType[];
  noResultsMessage?: {
    title?: string;
    desc?: string;
  };
  // checkoutStatus?: CheckoutStatusType;
  // drugStatus?: DrugStatusType;
  loadingStatus?: Record<string, any>;
  // deliveryFee?: string | number;
  // config
  hideDescription?: boolean;
  // callback
  onSeeDetail?: (data: any, id: number, deposit?: any) => any;
  onPayment?: (data: any, type: PaymentType, index: number, link?: string) => any;
  onRefresh?: () => any;
} & Pick<State,"language">

// Images
const IMAGES = {
  consent: "/images/register/consent.png",
};

// Const
const BUTTON_ACTIONS = {
  detail: "_DETAIL",
  payment: "_PAYMENT",
};

const CARD_PAYMENT_LIST = "CardPaymentList";

export const ACTION_DETAIL = `${CARD_PAYMENT_LIST}${BUTTON_ACTIONS.detail}`;
export const ACTION_PAYMENT = `${CARD_PAYMENT_LIST}${BUTTON_ACTIONS.payment}`;

const CardPaymentList = (props: CardPaymentListProps) => {
  // -const classes = useStyles();
  const intl = useIntl()
  const getTotalAmount = useCallback((item: PaymentDataType, index: number) => {
    const price =
      item.receipts?.[index]?.price ||
      item.receipt?.price ||
      item.invoice?.price;

    return Number(item.delivery_fee)
      ? Number(price) + Number(item.delivery_fee)
      : price;
  }, []);

  const handleSortDate = (a: any, b: any) => {
    return moment(a.created, moment.ISO_8601).isAfter(
      moment(b.created, moment.ISO_8601)
    )
      ? 1
      : -1;
  };

  const allPendingList = useMemo(() => {
    const invoiceList = (props.episodePaymentList || []).map((items: any) => {
      return {
        type: "pay",
        created: items.started,
        item: { ...items },
      };
    });

    const paymentList = (props.depositPaymentList || []).map((items: any) => {
      return {
        type: "deposit",
        created: items.created,
        item: { ...items },
      };
    });
    
    return [...invoiceList, ...paymentList].sort((a: any, b: any) =>
        handleSortDate(a, b)
      )
  }, [props.episodePaymentList, props.depositPaymentList]);

  if (props.loading) {
    return (
      <>
        {[1, 2].map((id) => (
          <CardPaymentSkeleton key={id} />
        ))}
      </>
    );
  } else if (props.checking) {
    return (
      <CardVerifyingPayment
        // callback
        onRefresh={props.onRefresh}
      />
    );
  } else if (
    props.episodePaymentList?.length ||
    props.depositPaymentList?.length
  ) {
    return (
      <>
        {props.paymentType === "receipt" ? (
          <>
            {props.episodePaymentList.map((item) =>
              (item.receipts || []).map((items, index) => (
                <CardPayment
                  key={items.id}
                  data={item}
                  keyId={items.id}
                  checkoutStatus={items.checkout_status}
                  drugStatus={items.drug_status}
                  type={props.paymentType}
                  // payment
                  totalAmount={getTotalAmount(item, index)}
                  // config
                  detailLoading={
                    props.loadingStatus?.[`${ACTION_DETAIL}_${items.id}`]
                  }
                  paymentLoading={
                    props.loadingStatus?.[`${ACTION_PAYMENT}_${items.id}`]
                  }
                  hideDescription={props.hideDescription}
                  // isReceipt={true}
                  // callback
                  onSeeDetail={props.onSeeDetail}
                  onPayment={props.onPayment}
                  language={props.language}
                />
              ))
            )}
          </>
        ) : (
          <>
            {allPendingList.map((items) => {
              if (items.type === "deposit") {
         
                return (
                  <CardPayment
                    key={items.item.id}
                    dataDeposit={items.item}
                    keyId={items.item.id}
                    checkoutStatus={items.item.checkout_status}
                    drugStatus={items.item.drug_status}
                    type={"deposit"}
                    // payment
                    totalAmount={items.item.total}
                    // config
                    detailLoading={
                      props.loadingStatus?.[`${ACTION_DETAIL}_${items.item.id}`]
                    }
                    paymentLoading={
                      props.loadingStatus?.[
                        `${ACTION_PAYMENT}_${items.item.id}`
                      ]
                    }
                    hideDescription={props.hideDescription}
                    // isReceipt={true}
                    // callback
                    onSeeDetail={props.onSeeDetail}
                    onPayment={props.onPayment}
                    language={props.language}
                  />
                );
              } else {
                return (
                  <CardPayment
                    key={items.item.id}
                    data={items.item}
                    keyId={items.item.id}
                    checkoutStatus={items.item.checkout_status}
                    drugStatus={items.item.drug_status}
                    type={props.paymentType}
                    // payment
                    totalAmount={getTotalAmount(items.item, -1)}
                    // config
                    detailLoading={
                      props.loadingStatus?.[`${ACTION_DETAIL}_${items.item.id}`]
                    }
                    paymentLoading={
                      props.loadingStatus?.[
                        `${ACTION_PAYMENT}_${items.item.id}`
                      ]
                    }
                    hideDescription={props.hideDescription}
                    // isReceipt={true}
                    // callback
                    onSeeDetail={props.onSeeDetail}
                    onPayment={props.onPayment}
                    language={props.language}
                  />
                );
              }
            })}
          </>
        )}
      </>
    );
  } else {
    return (
      <ItemNotFound
        img={IMAGES.consent}
        title={
          props.noResultsMessage ? props.noResultsMessage.title : intl.formatMessage({id: "bplusClinicKey1136"})
        }
        subTitle={
          props.noResultsMessage
            ? props.noResultsMessage.desc
            : intl.formatMessage({id: "bplusClinicKey1137"})
        }
      />
    );
  }
};

export default React.memo(CardPaymentList);
