import React, { useState, useRef, useEffect } from "react";
import "./App.css";
import "./App-tab.css";
import Diag, {
  executeAnswer,
  prepareForm,
} from "react-lib/apps/IsHealth/Common/Diag";
import ModInfo from "react-lib/apps/common/ModInfo";
import ModConfirm from "react-lib/apps/common/ModConfirm";
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import config from "../config/config";
import { Modal, Button, Segment, Dimmer, Loader } from 'semantic-ui-react';

const DiagForm = (props) => {
  const isMounted = useRef(true);
  const history = useHistory();
  const [patientId, setPatientId] = useState(null);
  const [formId, setFormId] = useState(null);
  const [chatChannel, setChatChannel] = useState(null);
  const [rule, setRule] = useState(null);
  const [form, setForm] = useState({});
  const [trigger, setTrigger] = useState(true);
  const [resultMessage, setResultMessage] = useState("");
  // Open Modal
  const [openResultInfo, setOpenResultInfo] = useState(false);
  const [openResultConfirm, setOpenResultConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFinish, setIsLoadingFinish] = useState(false);

  const apiToken = Cookies.get("apiToken");

  // Handle modal
  const showResultInfo = () => {
    setOpenResultInfo(true);
  };
  const hideResultInfo = () => {
    setOpenResultInfo(false);
  };
  const handleResultInfoApprove = () => {
    hideResultInfo();
    props.onFinished(chatChannel);
  };

  const showResuleConfirm = () => {
    setOpenResultConfirm(true);
  };
  const hideResultConfirm = () => {
    setOpenResultConfirm(false);
  };
  const handleResultUnConfirm = () => {
    // Left
    console.log("Left");
    if (typeof window.iosNative !== "undefined") {
      window.iosNative.diagUnConfirm();
    } else if (typeof window.MobNative !== "undefined") {
      window.MobNative.diagUnConfirm();
    }
    hideResultConfirm();
    props.onFinished(chatChannel);
  };
  const handleResultConfirm = async () => {
    // Right
    console.log("Right");
    if (typeof window.iosNative !== "undefined") {
      window.iosNative.diagConfirm();
    } else if (typeof window.MobNative !== "undefined") {
      window.MobNative.diagConfirm();
    }
    createEncounter();
  };

  const loadPatient = async () => {
    console.log("load patient: " + props.apiToken);
    const [data, error] = await props.controller.getPatient(props.apiToken);
    if (!isMounted.current) {
      return;
    }
    if (data) {
      setPatientId(data.patient);
    }
  };

  const createDiagForm = async (patient, diagRuleId) => {
    setIsLoading(true);
    let id = diagRuleId
      ? diagRuleId
      : props.match
      ? parseInt(props.match.params.id)
      : null;
    const [data, error] = await props.controller.createDiagForm(
      props.apiToken,
      patient,
      id,
      undefined, 
      undefined, 
      undefined, 
      undefined, 
      true
    );
    if (!isMounted.current) {
      return;
    }
    if (error) {
      console.log("'Cannot create DiagForm!");
      return;
    }
    setIsLoading(false);
    setFormId(data.id);
  };

  const updateDiagForm = async (
    answers,
    isFinished,
    { triageLevel, text } = {}
  ) => {
    // params: {props.apiToken, diagFormId, answers, finished, triageLevel, text}
    if (!formId) {
      console.log("Error : No Form ID!!!");
      return;
    }
    if (isFinished) {
      setIsLoadingFinish(true)
    }
    let params = {
      apiToken: props.apiToken,
      diagFormId: formId,
      answers: answers,
      finished: isFinished,
    };
    if (triageLevel) {
      params.triageLevel = triageLevel;
    }
    if (text) {
      params.text = text;
    }
    const [data, error] = await props.controller.updateDiagForm(params);
    if (isFinished) {
      setIsLoadingFinish(false)
    }
    if (!isMounted.current) {
      return;
    }
    if (error) {
      console.log("Error: Cannot update DiagForm ", formId);
      console.log(JSON.stringify(error));
      return;
    }

    let chatChannel = data.chat_channel;
    setChatChannel(chatChannel);
  };

  const createEncounter = async () => {
    if (!config.OPEN_FORM_ENCOUNTER) {
      return;
    }
    setIsLoading(true);
    let id = formId ? formId : props.match ? props.match.params.id : null;
    const [data, error] = await props.controller.createEncounter(
      props.apiToken,
      id
    );
    setIsLoading(false);
    // console.log("..... data: ", data, ", error: ", error);
    hideResultConfirm();
    props.onFinished(chatChannel);
  };

  const checkTargetLavel = async (division, triageLevel, msg) => {
    setIsLoadingFinish(true)
    const [data, error] = await props.controller.getTriageLeve(
      props.apiToken,
      division,
      triageLevel
    );
    setIsLoadingFinish(false)
    if (data) {
      if (data.length > 0) {
        let message = `${msg} \nคุณต้องการให้เราสร้าง Case เพื่อติดต่อกับโรงพยาบาลไหม?`;
        setResultMessage(message);
        showResuleConfirm();
      } else {
        setResultMessage(msg);
        showResultInfo();
      }
    }
  };

  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      if (props.noApiToken) {
        props.noApiToken();
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.controller && props.apiToken) {
      loadPatient();
    }
  }, [props.controller, props.apiToken]);

  useEffect(() => {
    if (props.diagRule) {
      var obj = prepareForm(props.diagRule);

      setForm(obj);
      setRule(props.diagRule);
    }
  }, [props.diagRule]);

  useEffect(() => {
    if (props.match && props.match.params.id) {
      getPublishDiagRuleDetail({ publishDiagRuleId: props.match.params.id });
    }
  }, [props.match.params.id]);

  useEffect(() => {
    let urlDiagId = props.match ? props.match.params.id : null;
    if (patientId && (props.diagRule || urlDiagId)) {
      createDiagForm(patientId, props.diagRule ? props.diagRule.id : null);
    }
  }, [patientId]);

  const getPublishDiagRuleDetail = async ({ publishDiagRuleId } = {}) => {
    const [response, error, network] =
      await props.controller.getPublishDiagRuleDetail({
        publishDiagRuleId: publishDiagRuleId,
        apiToken: apiToken ? apiToken : props.apiToken,
      });
    if (response) {
      var obj = prepareForm(response);

      setForm(obj);
      let newObj = { ...obj };
      newObj["division"] = {};
      newObj["division"]["id"] = response.division_id
        ? response.division_id
        : null;
      setRule(newObj);
    }
  };

  return (
    <div with="100%" className="diag-form">
      <ModConfirm
        open={openResultConfirm}
        titleColor={"red"}
        titleName={"ผลการประเมินความเสี่ยง."}
        alertText={resultMessage}
        onButtonLeftClick={handleResultUnConfirm}
        onButtonRightClick={handleResultConfirm}
        buttonLeftLoading={isLoading}
        buttonRightLoading={isLoading}
      />
      <ModInfo
        generalBlue
        open={openResultInfo}
        type={"success"}
        titleName={"ผลการประเมินความเสี่ยง"}
        alertText={resultMessage}
        onApprove={handleResultInfoApprove}
        onClose={hideResultInfo}
        buttonLeftLoading={isLoading}
        buttonRightLoading={isLoading}
        closeOnDimmerClick={false}
      />
      {isLoadingFinish && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            zIndex: 2000,
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
          }}
        >
          <Dimmer active={true}>
            <Loader inverted>Loading</Loader>
          </Dimmer>
        </div>
      )}
   
      {patientId && (
        <Diag
          trigger={trigger}
          form={form}
          isLoading={isLoading}
          onAnswerSelected={(action, event, data) => {
            executeAnswer(
              action,
              event,
              data,
              form,
              setForm,
              trigger,
              setTrigger,
              rule,
              updateDiagForm,
              checkTargetLavel
            );
          }}
          width={400}
          height={500}
        />
      )}
    </div>
  );
};

export const DiagFormAnonymous = (props) => {
  var form = props.form;
  return (
    <div with="100%" className="diag-form-anonymous">
      <Diag
        form={form}
        // setAnswer={(id) => { form[id].selected=true; props.saveForm(form);}}
        width={400}
        height={500}
      />
    </div>
  );
};

export default DiagForm;

// Move to executeAnswer in Diag.js
// const handleAnswer = (id) => {
//   let target = form[id];
//   let isFinished = (target.type === "end"); // add another condition that extra has also been filled
//   form[id].selected = true;
//   setForm(form)
//   setTrigger(!trigger)

//   var answers = {}
//   for (var [id, item] of Object.entries(form)) {
//     if ("selected" in item) {
//       answers[id] = item.selected
//     }
//     else {
//       return null
//     }
//   }

//   if (isFinished) {
//     // Check triage level
//     let division = rule.division.id;
//     let triageLavel = target.triage_level;
//     let msg = target.text;
//     updateDiagForm(JSON.stringify(answers), isFinished, {triageLevel: triageLavel, text: msg});
//     checkTargetLavel(division, triageLavel, msg);
//   } else {
//     updateDiagForm(JSON.stringify(answers), isFinished, {});
//   }
// }
