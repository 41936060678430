import WasmController from "react-lib/frameworks/WasmController";

// interface
import * as MobPHRI from "./MobPHRInterface";
import * as CheckupI from "./phr/CheckupInterface";
import * as MedicalCertificateI from "./phr/MedicalCertificateInterface";
import * as FeedInterface from "./native/FeedInterface";
import * as SelectHospitalI from "./appointment/SelectHospitalInterface"

console.log(" MobPHRController !!! init ");
if (typeof globalThis !== "undefined" && typeof globalThis.mobile === "undefined") {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobPHRController extends WasmController<
  MobPHRI.State,
  MobPHRI.Event,
  MobPHRI.Data
> {
  constructor(
    getState: () => MobPHRI.State,
    setState: (state: MobPHRI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobPHRI.DataInitial);
  }

  handleEvent = async (e: MobPHRI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Setup
      case "DidMount":
        MobPHRI.DidMount(this, e.params);
        return;
      case "DidUpdate":
        MobPHRI.DidUpdate(this, e.params);
        return;
      case "closeSnackMessage":
        MobPHRI.closeSnackMessage(this);
        return;

      // Mobile
      case "GetLoginInfo":
        globalThis.mobile?.getLoginInfo();
        return;
      case "handleMobileDownloadMedCert":
        if (globalThis.mobile?.downloadMedCert) {
          globalThis.mobile.downloadMedCert(e.params.url,e.params.filename);
        }
        return;
      case "handleMobileDownloadCheckup":
        if (globalThis.mobile?.downloadCheckup) {
          globalThis.mobile.downloadCheckup(e.params.url,e.params.filename);
        }
        return;
      case "HandleSetOpenBurger" :
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative" :
        globalThis.mobile.setBackToNative();
        return;

      case "GetListHospital":
        SelectHospitalI.GetListHospital(this, e.params);
        return;

      case "HandleSelectedHospital":
        SelectHospitalI.HandleSelectedHospital(this, e.params);
        return;

      // MedicalCertificateI
      case "handleGetMedicalCertificateList":
        MedicalCertificateI.handleGetMedicalCertificateList(this, e.params);
        return;

      // CheckupI
      case "handleGetCheckupList":
        CheckupI.handleGetCheckupList(this, e.params);
        return;

      case "HandleEvent":
        globalThis.mobile.handleEvent?.(e.params.name);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
