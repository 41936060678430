import React, { CSSProperties, ReactElement, useState } from "react";
// MUI
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import makeStyles from "@mui/styles/makeStyles";

// UX
import PaymentAlertMessage from "./PaymentAlertMessage";
// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";
import { FormattedMessage } from "react-intl";

// Types
type ReceivingMedicineAddressProps = {
  shippingAddress?: Record<string, any>;
  pharmacyLocation: string | ReactElement;
  // config
  textOnly?: boolean;
  // style
  style?: CSSProperties;
};

// Images
const IMAGES = {
  pin: "/images/payment/pin-location-blue.png",
};

// Styles
const COLORS = {
  card: "rgba(1, 71, 163, 0.05)",
  bg: "rgba(249, 251, 253, 1)",
  blue: "var(--blue-bdms-color)",
  grey: "rgba(121, 120, 120, 1)",
  half_blue: "rgba(204, 218, 237, 0.5)",
};

const useStyles = makeStyles(() => ({
  card_address: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      margin: "0rem 1.5rem 0",
      backgroundColor: COLORS.card,
      borderRadius: "8px",
      "& .MuiCardContent-root": {
        padding: "1.5rem 1.35rem",
      },
      "& .title": {
        fontSize: "1.125rem",
        color: COLORS.blue,
        fontWeight: "bold",
      },
      "& .address": {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        color: COLORS.grey,
        alignItems: "center",
        padding: "0.85rem 0",
        "& img": {
          width: "1.25rem",
          marginRight: "0.55rem",
        },
      },
      "& .description": {
        color: COLORS.blue,
        fontSize: "1.125rem",
        textDecoration: "underline",
      },
    },
  },
}));

const ReceivingMedicineAddress = (props: ReceivingMedicineAddressProps) => {
  const classes = useStyles();

  const [openModDescription, setOpenModDescription] = useState<boolean>(false);

  const handleOpenModDescription = () => {
    setOpenModDescription(true);
  };

  return (
    <>
      <Card className={classes.card_address} style={props.style}>
        <CardActionArea disableRipple={true} disableTouchRipple={true}>
          <CardContent>
            {props.shippingAddress?.self_pickup ? (
              <>
                <div className="title"><FormattedMessage id= "bplusClinicKey1173"/></div>
                <PaymentAlertMessage
                  type="info-blue"
                  textOnly={true}
                  style={{
                    margin: "0.75rem 0",
                    background: props.textOnly ? COLORS.half_blue : "",
                  }}
                >
                  <>{props.pharmacyLocation}</>
                </PaymentAlertMessage>
                {!props.textOnly && (
                  <div
                    aria-hidden="true"
                    className="description"
                    onClick={handleOpenModDescription}
                  >
                    รายละเอียดเพิ่มเติม
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="title"><FormattedMessage id="bplusClinicKey1170"/></div>
                <div className="address">
                  <div>
                    <img src={IMAGES.pin} />
                  </div>
                  <div>{props.shippingAddress?.full_address_text || "-"}</div>
                </div>
                <PaymentAlertMessage
                  type="info-blue"
                  textOnly={true}
                  style={{
                    background: props.textOnly ? COLORS.half_blue : "",
                  }}
                >
                  <FormattedMessage id="bplusClinicKey1171"/>
                </PaymentAlertMessage>
              </>
            )}
          </CardContent>
        </CardActionArea>
      </Card>

      <DialogReadMore
        open={openModDescription}
        title="ขั้นตอนการติดต่อรับยา"
        // callback
        onChange={setOpenModDescription}
      >
        <div>
          <ol style={{ padding: "0 0 1.5rem 1.5rem", lineHeight: 1.75 }}>
            <li>ติดต่อขอรับยาที่ห้องยาที่ระบุข้างต้น</li>
            <li>ไปที่ช่องทาง รับยาด่วน แจ้งเภสัช ขอรับยา</li>
            <li>แสดงใบเสร็จที่ท่านจ่ายแก่เภสัชกร</li>
            <li>รับยาและตรวจสอบเป็นอันเสร็จสิ้น</li>
          </ol>
        </div>
      </DialogReadMore>
    </>
  );
};

export default React.memo(ReceivingMedicineAddress);
