import React, {
  ChangeEvent,
  MutableRefObject,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { Box, Button as MuiButton } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { makeStyles, Typography } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import LoadingButton from "@mui/lab/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import { useIntl } from "react-intl";
import SaveIcon from "@mui/icons-material/Save";
import PreferredTransferList from "./PreferredTransferList";

import { PREFERRED_MODE } from "./Preferred";
import { Button } from "semantic-ui-react";

const useStyles = makeStyles(() => ({
  thaiTextFieldInputProps: {
    paddingTop: "5px",
    height: "20px",
  },
  icon: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "25px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .MuiButton-root.MuiButton-contained": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
  title: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.0375em",
    fontWeight: "bold",
    fontSize: "1.25rem",
  },
  title_section: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.0375em",
    fontWeight: "bold",
    fontSize: "14px",
  },
  title_normal: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.0375em",
    fontSize: "14px",
  },
  header: {
    color: "rgba(0, 0, 0, 0.6)",
    lineHeight: "1.0375em",
    fontWeight: "bold",
  },
  asterisk: {
    color: "red",
  },
}));

const COLORS = {
  red: "#DA0000",
};

type PreferredEditorHomeProps = {
  onChange: any;
  selectedServices: any;
  typeOptions: any[];
  serviceOptions: any[];
  hospitalDivisionName: string;
  preferredLoading: any;
  onSave: any;
  showTransferList: boolean;
  mode: string;
  medProgramList?: any[];
  subspecialtiesList?: any[];
  onDeleteIcon: any;
};

const PreferredEditorHome = (props: PreferredEditorHomeProps) => {
  // const [backupIconFirebaseURL, setBackupIconFirebaseURL] = useState("");
  // const [backupIconName, setBackupIconName] = useState("")
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabledSave, setDisabledSave] = useState<boolean>(false);
  const intl = useIntl();
  const classes = useStyles();
  const iconRef = useRef() as MutableRefObject<HTMLInputElement>;

  const handleClickBrowse = () => {
    iconRef.current?.click?.();
  };

  const handleChangeLocalIconFile = (e: ChangeEvent<HTMLInputElement>) => {
    setDisabledSave(false);
    const file = e?.target?.files?.[0];

    // console.log("handleChangeLocalIconFile !!!");
    if (file) {
      // setBackupIconFirebaseURL("")
      if (props.mode === PREFERRED_MODE.HOME) {
        props.onChange?.({
          iconNameFromLocalFile: file.name,
          bufferIconFile: file,
          iconExternalURL: "",
          previewIconPathURL: window.URL.createObjectURL(file),
          previewIconName: file.name,
        });
      } else {
        if (props.selectedServices?.icon?.includes("http")) {
          props.onChange?.({
            icon: file.name,
            bufferIconFile: file,
            icon_url: "",
            previewIconPathURL: window.URL.createObjectURL(file),
            previewIconName: file.name,
            currentIcon: props.selectedServices?.icon,
          });
        } else {
          props.onChange?.({
            icon: file.name,
            bufferIconFile: file,
            icon_url: "",
            previewIconPathURL: window.URL.createObjectURL(file),
            previewIconName: file.name,
          });
        }
      }
    }
  };

  const handleClickIconURLSubmit = async () => {
    // คืนปุ่ม Save

    if (props.mode === PREFERRED_MODE.HOME) {
      setDisabledSave(false);
      props.onChange?.({
        previewIconPathURL: props.selectedServices?.iconExternalURL,
        previewIconName: props.selectedServices?.iconExternalURL?.substring(
          props.selectedServices?.iconExternalURL?.lastIndexOf("/") + 1
        ),
      });
    } else {
      setDisabledSave(false);
      props.onChange?.({
        previewIconPathURL: props.selectedServices?.icon_url,
        previewIconName: props.selectedServices?.icon_url?.substring(
          props.selectedServices?.icon_url?.lastIndexOf("/") + 1
        ),
      });
    }
  };

  const isUrl = (url: string): Boolean => {
    // console.log("url: ", url);
    try {
      let ret = Boolean(new URL(url));
      // console.log(" isUrl ", ret);
      return ret;
    } catch (e) {
      // console.log(" isUrl false");
      return false;
    }
  };

  // console.log("PreferredEditorHome disabledSave ", disabledSave )
  // console.log("PreferredEditorHome iconPathFromFirebase : ",    ( props.mode === PREFERRED_MODE.HOME ? (isUrl(props.selectedServices?.iconExternalURL) &&
  // props.selectedServices?.iconExternalURL !==
  //   props.selectedServices?.previewIconPathURL &&
  // props.selectedServices?.iconExternalURL !==
  //   props.selectedServices?.iconPathFromFirebase) : false) )

  //   console.log("PreferredEditorHome display : " , (
  //   props.mode === PREFERRED_MODE.HOME
  //   ? props.selectedServices?.title_th || ""
  //   : props.selectedServices?.display || ""
  // ));

  // console.log("PreferredEditorHome display_en : ", (
  //   props.mode === PREFERRED_MODE.HOME
  //   ? props.selectedServices?.title_en || ""
  //   : props.selectedServices?.display_en || ""
  // ));

  // console.log("PreferredEditorHome type : ", (props.selectedServices?.type || "") )
  // console.log("PreferredEditorHome service: ",(props.mode === PREFERRED_MODE.HOME ? (props.selectedServices?.service || "") : true))

  useEffect(() => {
    console.log("useEffect props.selectedServices: ", props.selectedServices);

    console.log("useEffect inputRef?.current: ", iconRef?.current);

    if (props.selectedServices && iconRef?.current?.value) {
      iconRef.current.value = "";
    }
  }, [props.selectedServices]);

  return (
    <>
      <Stack spacing={3} style={isLoading ? { pointerEvents: "none" } : {}}>
        <Typography className={classes.title}> Menu name </Typography>

        <Stack direction="row" spacing={2}>
          <TextField
            required
            id="title-thai-required"
            label="Thai"
            placeholder="รายการใหม่"
            value={
              props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.title_th || ""
                : props.selectedServices?.display || ""
            }
            InputLabelProps={{
              shrink: true,
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            fullWidth
            rows={1}
            onChange={(e) => {
              if (props.mode === PREFERRED_MODE.HOME) {
                props.onChange?.({ title_th: e.target.value });
              } else {
                props.onChange?.({ display: e.target.value });
              }
            }}
          />

          <TextField
            required
            id="title-english-required"
            label="English"
            placeholder="New Services"
            value={
              props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.title_en || ""
                : props.selectedServices?.display_en || ""
            }
            InputLabelProps={{
              shrink: true,
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            fullWidth
            rows={1}
            onChange={(e) => {
              if (props.mode === PREFERRED_MODE.HOME) {
                props.onChange?.({ title_en: e.target.value });
              } else {
                props.onChange?.({ display_en: e.target.value });
              }
            }}
          />
        </Stack>

        <Autocomplete
          key={"type-" + props.selectedServices?.id}
          id="type-outlined"
          value={
            props.mode === PREFERRED_MODE.HOME
              ? props.typeOptions?.find(
                  (item: any) => item === props.selectedServices?.type
                )
              : props.typeOptions?.find(
                  (item: any) => item.key === props.selectedServices?.type
                )?.label || ""
          }
          options={props.typeOptions}
          onChange={(event, newValue) => {
            // console.log(" newVale", newValue);
            if (props.mode === PREFERRED_MODE.HOME) {
              props.onChange?.({ type: newValue });
            } else {
              props.onChange?.({ type: newValue?.key });
            }
          }}
          clearIcon={false}
          disableCloseOnSelect={false}
          renderInput={(params: any) => {
            // console.log("params", params)
            return (
              <TextField
                required
                {...params}
                label={intl.formatMessage({ id: "Type" })}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    asterisk: classes.asterisk,
                  },
                }}
              />
            );
          }}
        />
        {!props.showTransferList && (
          <Autocomplete
            key={"service-" + props.selectedServices?.id}
            id="service-outlined"
            value={props.selectedServices?.service || ""}
            options={props.serviceOptions}
            onChange={(event, newValue) => {
              props.onChange?.({ service: newValue });
            }}
            clearIcon={false}
            disableCloseOnSelect={false}
            renderInput={(params: any) => {
              // console.log(" params", params)

              return (
                <TextField
                  required
                  {...params}
                  label={intl.formatMessage({ id: "Service" })}
                  InputLabelProps={{
                    shrink: true,
                    classes: {
                      asterisk: classes.asterisk,
                    },
                  }}
                />
              );
            }}
          />
        )}

        {props.selectedServices?.service === "Emergency" && (
          <TextField
            required
            id="Emergency"
            label="Emergency no."
            value={props.selectedServices?.emergency_no}
            fullWidth
            InputLabelProps={{
              shrink: true,
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            onChange={(e: any) => {
              props.onChange?.({ emergency_no: e.target.value });
            }}
            InputProps={{ type: "number" }}
          />
        )}

        {props.selectedServices?.service === "PartnerService" && (
          <TextField
            required
            id="partner-link"
            label="Partner Link"
            value={props.selectedServices?.partnerLink || ""}
            fullWidth
            InputLabelProps={{
              shrink: true,
              classes: {
                asterisk: classes.asterisk,
              },
            }}
            onChange={(e) => {
              props.onChange?.({ partnerLink: e.target.value });
            }}
          />
        )}

        <TextField
          id="hospital"
          label="Hospital"
          value={
            Object.keys(props.selectedServices)?.length > 0
              ? props.hospitalDivisionName
              : ""
          }
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />

        {props.showTransferList && props.selectedServices?.type ? (
          <PreferredTransferList
            type={props.selectedServices?.type}
            medProgramList={props.medProgramList}
            subspecialtiesList={props.subspecialtiesList}
            selectedServices={props.selectedServices}
            typeOptions={props.typeOptions}
            // selectedMedProgram={props.selectedServices?.med_programs}
            // selectedSubSpecialties={props.selectedServices?.subspecialties}
            onChange={props.onChange}
          />
        ) : null}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {props.selectedServices?.iconPathFromFirebase ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "10px",
              }}
            >
              <Typography className={classes.header}>Current Icon </Typography>
              <Box
                style={{ margin: "10px" }}
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={props.selectedServices?.iconPathFromFirebase}
              />
              {props.selectedServices?.iconPathFromFirebase && (
                <Button
                  icon="trash alternate"
                  color="red"
                  onClick={props.onDeleteIcon}
                />
              )}
            </div>
          ) : null}

          {props.selectedServices?.currentIcon ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: "100px",
              }}
            >
              <Typography className={classes.header}>Current Icon </Typography>
              <Box
                style={{ margin: "10px" }}
                component="img"
                sx={{
                  height: 60,
                  width: 60,
                  maxHeight: { xs: 233, md: 167 },
                  maxWidth: { xs: 350, md: 250 },
                }}
                src={props.selectedServices?.currentIcon}
              />
              {props.selectedServices?.currentIcon && (
                <Button
                  icon="trash alternate"
                  color="red"
                  onClick={props.onDeleteIcon}
                />
              )}
            </div>
          ) : null}

          {props.selectedServices?.previewIconPathURL ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography className={classes.header}>Previews Icon</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <Box
                  id="preferred-icon-previews"
                  style={{ margin: "10px" }}
                  component="img"
                  sx={{
                    height: 60,
                    width: 60,
                    maxHeight: { xs: 233, md: 167 },
                    maxWidth: { xs: 350, md: 250 },
                  }}
                  src={props.selectedServices?.previewIconPathURL}
                  onError={(error) => {
                    console.error("error: ", error);
                    // เกิดได้เฉพาะ การ ใส่ URL
                    props.onChange?.({
                      previewIconPathURL: "",
                      iconExternalURL: "",
                    });
                  }}
                  onLoad={(
                    event: React.SyntheticEvent<HTMLImageElement, Event>
                  ) => {
                    if (props.mode === PREFERRED_MODE.HOME) {
                      if (
                        // ถ้าเท่า เกิดจาก mode URL อยุู่แล้ว clear ฝั่ง local file
                        props.selectedServices?.previewIconPathURL ===
                        props.selectedServices?.iconExternalURL
                      ) {
                        // from Submit
                        iconRef.current.value = "";
                        props.onChange?.({
                          iconNameFromLocalFile: "",
                          bufferIconFile: null,
                        });
                      }
                    } else {
                      if (
                        // ถ้าเท่า เกิดจาก mode URL อยุู่แล้ว clear ฝั่ง local file
                        props.selectedServices?.previewIconPathURL ===
                        props.selectedServices?.icon_url
                      ) {
                        // from Submit
                        iconRef.current.value = "";
                        props.onChange?.({
                          icon: "",
                          bufferIconFile: null,
                        });
                      }
                    }
                  }}
                />
                <div> {props.selectedServices?.previewIconName}</div>
              </div>
            </div>
          ) : null}
        </div>
        {props.mode === PREFERRED_MODE.APPOINTMENT && (
          <div>
            <div className={classes.title_section}>
              <label>{`Top Service Icon `}</label>
              <label style={{ color: "red" }}>{`*`}</label>
            </div>
            <label className={classes.title_normal}>
              {`(ขนาดควรมากกว่า 500 x 500 px และพื้นหลังเป็น Transparent background)`}
            </label>
          </div>
        )}
        <div className={classes.icon}>
          <TextField
            // required={props.mode === PREFERRED_MODE.APPOINTMENT}
            id={
              props.mode === PREFERRED_MODE.HOME
                ? "icon-browse"
                : "icon-browse-required"
            }
            label={`${
              props.mode === PREFERRED_MODE.APPOINTMENT
                ? "Import Icon"
                : "ICON (500 x 500 px)"
            }`}
            value={
              props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.iconNameFromLocalFile || ""
                : props.selectedServices?.icon?.includes("http")
                ? props.selectedServices?.icon
                    .substring(
                      props.selectedServices?.icon.lastIndexOf("/") + 1
                    )
                    .split("?")[0]
                : props.selectedServices?.icon || ""
            }
            fullWidth
            InputProps={{
              disabled: true,
            }}
            InputLabelProps={{
              shrink: true,
              ...(props.mode === PREFERRED_MODE.APPOINTMENT && {
                classes: { asterisk: classes.asterisk },
              }),
            }}
            // style={{ width: "100%" }}
          />
          <MuiButton
            variant="contained"
            color="primary"
            style={{ height: "45px", minWidth: "100px" }}
            onClick={handleClickBrowse}
          >
            Browse
          </MuiButton>

          <input
            ref={iconRef}
            type="file"
            accept="image/*"
            id="file"
            onChange={handleChangeLocalIconFile}
            hidden
          />
        </div>

        <div className={classes.icon}>
          <TextField
            // required={props.mode === PREFERRED_MODE.APPOINTMENT}
            id={
              props.mode === PREFERRED_MODE.HOME
                ? "icon-url"
                : "icon-url-required"
            }
            label="Icon URL"
            value={
              props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.iconExternalURL || ""
                : props.selectedServices?.icon_url || ""
            }
            fullWidth
            onBlur={() => {
              if (props.mode === PREFERRED_MODE.HOME) {
                // ลบ text ออกยังไม่ทันจะ submit ก็ คืนให้ Save ได้ปกติ
                if (
                  !props.selectedServices?.iconExternalURL &&
                  !props.selectedServices?.previewIconPathURL
                ) {
                  setDisabledSave(false);
                }

                // กรณี ใส่ Preview แล้ว ลบ url ออก ทั้งหมด ให้ auto clear และ คืน Save ได้ปกติ
                if (
                  props.selectedServices?.iconExternalURL === "" &&
                  props.selectedServices?.previewIconPathURL &&
                  props.selectedServices?.iconNameFromLocalFile === ""
                ) {
                  setDisabledSave(false);
                  if (props.selectedServices?.iconExternalURL === "") {
                    props.onChange?.({
                      previewIconPathURL: "",
                      previewIconName: "",
                    });
                  }
                }

                // กรณี แก้ URL ออก หลัง โหลด preview และ ไม่ใช่ mode local file
                if (
                  props.selectedServices?.iconNameFromLocalFile === "" &&
                  props.selectedServices?.iconExternalURL !== "" &&
                  props.selectedServices?.previewIconPathURL !== "" &&
                  props.selectedServices?.iconExternalURL !==
                    props.selectedServices?.previewIconPathURL
                ) {
                  setDisabledSave(false);
                  props.onChange?.({
                    previewIconPathURL: "",
                    previewIconName: "",
                  });
                }
              } else {
                // ลบ text ออกยังไม่ทันจะ submit ก็ คืนให้ Save ได้ปกติ
                if (
                  !props.selectedServices?.icon_url &&
                  !props.selectedServices?.previewIconPathURL
                ) {
                  setDisabledSave(false);
                }

                // กรณี ใส่ Preview แล้ว ลบ url ออก ทั้งหมด ให้ auto clear และ คืน Save ได้ปกติ
                if (
                  props.selectedServices?.icon_url === "" &&
                  props.selectedServices?.previewIconPathURL &&
                  props.selectedServices?.icon === ""
                ) {
                  setDisabledSave(false);
                  if (props.selectedServices?.icon_url === "") {
                    props.onChange?.({
                      previewIconPathURL: "",
                      previewIconName: "",
                    });
                  }
                }

                // กรณี แก้ URL ออก หลัง โหลด preview และ ไม่ใช่ mode local file
                if (
                  props.selectedServices?.icon === "" &&
                  props.selectedServices?.icon_url !== "" &&
                  props.selectedServices?.previewIconPathURL !== "" &&
                  props.selectedServices?.icon_url !==
                    props.selectedServices?.previewIconPathURL
                ) {
                  setDisabledSave(false);
                  props.onChange?.({
                    previewIconPathURL: "",
                    previewIconName: "",
                  });
                }
              }
            }}
            onChange={(e) => {
              if (e.target.value !== "") {
                setDisabledSave(true);
              } else if (e.target.value === "") {
                setDisabledSave(false);
              }

              if (props.mode === PREFERRED_MODE.HOME) {
                props.onChange?.({ iconExternalURL: e.target.value });
              } else {
                props.onChange?.({ icon_url: e.target.value });
              }
            }}
            InputLabelProps={{
              shrink: true,
              ...(props.mode === PREFERRED_MODE.APPOINTMENT && {
                classes: { asterisk: classes.asterisk },
              }),
            }}
            // style={{ width: "100%" }}
          />
          <MuiButton
            variant="contained"
            color="primary"
            style={{ height: "45px", minWidth: "100px" }}
            onClick={handleClickIconURLSubmit}
            disabled={
              props.mode === PREFERRED_MODE.HOME
                ? // disabled submit : 1. iconExternalURL มีค่าแต่ form URL ไม่ได้ , 2 เป็นค่าว่าง
                  (!isUrl(props.selectedServices?.iconExternalURL) &&
                    props.selectedServices?.iconExternalURL !== "") ||
                  props.selectedServices?.iconExternalURL === ""
                : (!isUrl(props.selectedServices?.icon_url) &&
                    props.selectedServices?.icon_url !== "") ||
                  props.selectedServices?.icon_url === ""
            }
          >
            Submit
          </MuiButton>
        </div>

        <TextField
          required
          id="tag-required"
          label="Sequence"
          value={
            props.mode === PREFERRED_MODE.HOME
              ? props.selectedServices?.sequence || ""
              : props.selectedServices?.seq || ""
          }
          onChange={(e) => {
            if (props.mode === PREFERRED_MODE.HOME) {
              props.onChange?.({ sequence: e.target.value });
            } else {
              props.onChange?.({ seq: e.target.value });
            }
          }}
          fullWidth
          InputLabelProps={{
            shrink: true,
            classes: {
              asterisk: classes.asterisk,
            },
          }}
          InputProps={{ type: "number" }}
        />

        <div style={{ margin: "10px 0 10px 0px", fontWeight: "bolder" }}>
          <FormControlLabel
            control={<Checkbox />}
            label="Active"
            style={{ color: COLORS.red, fontWeight: "bold" }}
            checked={props.selectedServices?.active || false}
            onClick={(e: any) => {
              props.onChange?.({ active: !props.selectedServices?.active });
            }}
          />
        </div>

        <FormControl>
          <LoadingButton
            color="primary"
            loading={props.preferredLoading}
            loadingPosition="start"
            variant={isLoading ? "outlined" : "contained"}
            style={{ width: "30%", padding: "10px 0" }}
            disabled={
              disabledSave ||
              (props.selectedServices?.service === "PartnerService"
                ? !props.selectedServices?.partnerLink ||
                  !(props.selectedServices?.partnerLink || "").includes(
                    "https://"
                  )
                : false) ||
              (props.mode === PREFERRED_MODE.HOME
                ? isUrl(props.selectedServices?.iconExternalURL) &&
                  props.selectedServices?.iconExternalURL !==
                    props.selectedServices?.previewIconPathURL &&
                  props.selectedServices?.iconExternalURL !==
                    props.selectedServices?.iconPathFromFirebase
                : false) ||
              !(props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.title_th || ""
                : props.selectedServices?.display || "") ||
              !(props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.title_en || ""
                : props.selectedServices?.display_en || "") ||
              !(props.selectedServices?.type || "") ||
              !(props.mode === PREFERRED_MODE.HOME
                ? props.selectedServices?.service || ""
                : true) ||
              !(props.mode === PREFERRED_MODE.APPOINTMENT
                ? props.selectedServices?.icon ||
                  props.selectedServices?.icon_url
                : true)
            }
            onClick={props.onSave}
            startIcon={<SaveIcon />}
          >
            {/* // ผู้ใช้ลืมกด Submit ก่อน Save ดูเงื่อนไข ว่าต้อง preview mode และ ไม่เท่ากัน
             */}
            {isUrl(props.selectedServices?.iconExternalURL) &&
            props.selectedServices?.iconExternalURL !==
              props.selectedServices?.previewIconPathURL &&
            props.selectedServices?.iconExternalURL !==
              props.selectedServices?.iconPathFromFirebase
              ? "SAVE ( Need Press Submit First)"
              : "SAVE"}
          </LoadingButton>
        </FormControl>
      </Stack>
    </>
  );
};

export default PreferredEditorHome;
