import React, {
  ReactElement,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Tab, TabProps } from "semantic-ui-react";

// MUI
import makeStyles from "@mui/styles/makeStyles";

import { useIntl } from "react-intl";
import SwipeableViews from "react-swipeable-views";
import Cookies from "js-cookie";
import moment from "moment";
import { History } from "history";

// UX
import CardPaymentList from "bplus-lib/telephar/CardPaymentList";
import ButtonPayment from "bplus-lib/telephar/ButtonPayment";

import {State} from "bplus-lib/MobPaymentInterface"
// Utils
import * as Util from "react-lib/utils";

// Types
type PatientPayment2Props = {
  // controller
  controller: any;
  // data
  patientData: Record<string, any>;
  apiToken?: string;
  history: History;
  match: {
    path: string;
    url: string;
    params: { divisionId: string };
  };
  selectedHospital?: Record<string, any>;
  // Element
  menuBar: ReactElement;
  // callback
  noApiToken: () => any;
  onSetBackToApp: (isBack: boolean) => any;
} & Pick<State, "language">

// Styles
const useStyles = makeStyles(() => ({
  screen: {
    position: "fixed",
    height: "calc(100vh)",
    width: "100%",
    "& > div:nth-child(3)": {
      height: "calc(100vh - 7rem)",
    },
    "& .react-swipeable-view-container > div > div": {
      height: "calc(100vh - 7rem)",
      padding: "0 15px 15px",
      overflow: "scroll",
      position: "relative",
      width: "100vw",
    },
  },
  no_patient: {
    position: "absolute",
    display: "grid",
    height: "100%",
    placeContent: "center",
    width: "100%",
    textAlign: "center",
    left: "0",
    "& h2": {
      marginTop: "-2rem",
    },
  },
}));

// Const
const INVOICE_STATUS = {
  BILLED: "BILLED",
  PAID: "PAID",
  CANCELED: "CANCELED",
};

const PatientPayment2 = (props: PatientPayment2Props) => {
  const classes = useStyles();
  const intl = useIntl();

  const [viewIndex, setViewIndex] = useState<number | string>(0);
  // data
  const [waitingInvoiceList, setWaitingInvoiceList] = useState<any[]>([]);
  const [noPatientId, setNoPatientId] = useState<boolean>(false);
  // -const [cancelInvoice, setCancelInvoice] = useState<any>([]);
  const [paidInvoiceList, setPaidInvoiceList] = useState<any[]>([]);

  // loading
  const [isLoading, setIsLoading] = useState(true);

  const isMounted = useRef(true);
  const intervalRef = useRef<any>(null);
  const paymentRef = useRef<any>(null);

  const apiToken = Cookies.get("apiToken");

  // Memo Effect
  const patientId = useMemo(() => {
    return props.patientData?.id || props.patientData?.patient;
  }, [props.patientData]);

  // Callback Effect
  const handleGetWaitingInvoice = useCallback(async () => {
    const [res] = await props.controller.getPendingInvoice({
      apiToken: props.apiToken ? props.apiToken : apiToken,
      isTelemed: "True",
    });

    if (isMounted.current) {
      if (res) {
        setWaitingInvoiceList(res);
      } else {
        setWaitingInvoiceList([]);
      }
    }
  }, [patientId, props.apiToken, props.match.params.divisionId]);

  const handleGetCancelInvoice = useCallback(async () => {
    // -const [res] = await props.controller.getInvoice({
    //   apiToken: props.apiToken ? props.apiToken : apiToken,
    //   patientId: patientId,
    //   status: INVOICE_STATUS.CANCELED,
    //   divisionId: props.match.params.divisionId,
    //   isTelemed: true,
    // });
    // if (isMounted.current) {
    //   // res have next & prev url, currently not use :: CHERRY 13/07/2020 15.33
    //   if (res) {
    //     setCancelInvoice(res.items);
    //   } else {
    //     setCancelInvoice([]);
    //   }
    // }
  }, [patientId, props.apiToken, props.match.params.divisionId]);

  const handleGetPaidInvoice = useCallback(async () => {
    const [res] = await props.controller.getCompleteInvoice({
      apiToken: props.apiToken ? props.apiToken : apiToken,
      fromDate: Util.formatDate(moment().subtract(7, "days")),
      isTelemed: "True",
      hospital: props.selectedHospital?.code,
    });

    if (isMounted.current) {
      console.log({ res });
      if (res) {
        setPaidInvoiceList(res);
      } else {
        setPaidInvoiceList([]);
      }
    }
  }, [
    patientId,
    props.apiToken,
    props.match.params.divisionId,
    props.selectedHospital,
  ]);

  const checkBackToApp = useCallback(async () => {
    if (typeof window.iosNative !== "undefined") {
      try {
        window.iosNative.consoleLog("backToApp");
        const backToApp = await window.iosNative.backToApp;
        window.iosNative.consoleLog(backToApp);

        if (backToApp === "true") {
          // ios can only send string back
          window.iosNative.consoleLog("have back to app");
          props.onSetBackToApp(true);
        }
      } catch (e: any) {
        console.error(e);
        console.log(e.message);
      }
    } else if (typeof window.MobNative !== "undefined") {
      const backToApp = await window.MobNative.backToApp();

      if (backToApp) {
        props.onSetBackToApp(true);
      }
    }
  }, []);

  const formatedData = useCallback(
    (item) => ({
      ...item,
      // id: item.id,
      // number: item.encounter_no,
      // doctor: { full_name: item.doctor_name, image_url: item.doctor_image_url },
      // division: { name: item.division_name },
      // started: item.created,
      // consult_doctors: [],
      // receipt_url: item.receipt_url,
      // invoice: {
      //   id: item.id,
      //   price: item.price,
      //   status: item.status_name,
      //   remark: "",
      // },
      hospital_name: props.selectedHospital?.name,
    }),
    [props.selectedHospital]
  );

  // Effect
  useEffect(() => {
    if (!props.apiToken && !apiToken) {
      props.noApiToken();
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    checkBackToApp();
  }, [window.iosNative, window.MobNative]);

  useEffect(() => {
    const init = async () => {
      if (patientId) {
        setNoPatientId(false);
        setIsLoading(true);

        await Promise.all([
          handleGetWaitingInvoice(),
          handleGetPaidInvoice(),
          handleGetCancelInvoice(),
        ]);

        intervalRef.current = setInterval(() => {
          handleGetWaitingInvoice();
        }, 16000);

        setIsLoading(false);
      } else if (patientId === null) {
        setNoPatientId(true);
        setIsLoading(false);
      }
    };

    init();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [patientId, props.match.params.divisionId, props.selectedHospital]);

  // Memo
  const waitingInvoiceItems = useMemo(() => {
    return waitingInvoiceList.map(formatedData);
  }, [waitingInvoiceList, props.selectedHospital]);

  const paidInvoiceItems = useMemo(() => {
    const sortByEdited = (a: any, b: any) => {
      if (a.started < b.started) {
        return 1;
      } else if (b.started < a.started) {
        return -1;
      } else {
        return 0;
      }
    };

    return (paidInvoiceList || []).sort(sortByEdited);
  }, [paidInvoiceList]);

  const panes = useMemo(() => {
    return [
      {
        menuItem: intl.formatMessage({ id: "payment.pending_payment" }),
      },
      {
        menuItem: intl.formatMessage({ id: "payment.paid" }),
      },
    ];
  }, [intl]);

  // Handler
  const handleTabChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: TabProps
  ) => {
    setViewIndex(data?.activeIndex || 0);
  };

  const handleChangeIndex = (index: number) => {
    setViewIndex(index);
  };

  const handleSeeDetail = (data: any) => {
    props.history.push(`/paymentDetail/${data.invoice?.id}/`);
  };

  const handlePayment = (data: any, type: string, index: number) => {
    if (type === "pay") {
      paymentRef.current?.onPayment?.(data.invoice.id);
    } else if (type === "receipt" && data.receipt?.receipt_url) {
      globalThis.location.href = data.receipt.receipt_url;
    }
  };

  return (
    <div className={classes.screen}>
      {props.menuBar}

      <Tab
        className="appointment-tab-custom --red --two"
        menu={{ secondary: true, pointing: true }}
        panes={panes}
        activeIndex={viewIndex}
        onTabChange={handleTabChange}
      />

      <SwipeableViews
        index={+viewIndex}
        onChangeIndex={handleChangeIndex}
        animateHeight={true}
      >
        <div>
          {noPatientId && !isLoading ? (
            <NoPatientId />
          ) : (
            <CardPaymentList
              loading={isLoading}
              episodePaymentList={waitingInvoiceItems}
              paymentType="pay"
              noResultsMessage={{ title: intl.formatMessage({id: "bplusClinicKey1135"}) }}
              // checkoutStatus="doctor_fee"
              // callback
              onSeeDetail={handleSeeDetail}
              onPayment={handlePayment}
              language={props.language}
            />
          )}
        </div>
        <div>
          {noPatientId && !isLoading ? (
            <NoPatientId />
          ) : (
            <CardPaymentList
              loading={isLoading}
              episodePaymentList={paidInvoiceItems}
              paymentType="receipt"
              // checkoutStatus="doctor_fee"
              noResultsMessage={{ title: intl.formatMessage({id: "bplusClinicKey1135"}) }}
              // config
              hideDescription={true}
              // callback
              onSeeDetail={handleSeeDetail}
              onPayment={handlePayment}
              language={props.language}
            />
          )}
        </div>
      </SwipeableViews>

      <ButtonPayment ref={paymentRef} controller={props.controller} />
    </div>
  );
};

const NoPatientId = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.no_patient}>
      <h2>
        {intl.formatMessage({ id: "common.an_error_occurred" })} <br />{" "}
        {intl.formatMessage({
          id: "payment.no_patient_id_error",
        })}
      </h2>
    </div>
  );
};

export default React.memo(PatientPayment2);
