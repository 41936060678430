
/* Generated apis ---------------------- */
import { to } from 'react-lib/utils'
import BaseService from 'react-lib/apis/services/BaseService'
import GeneratePermitAPI from "./GeneratePermitAPI_users"
import Cookies from "js-cookie";
import config from '../../config/config'

const HOST = `${config.FAST_API}`

const refreshPermit = async () => {
  const [result] = await GeneratePermitAPI.get({
    apiToken: Cookies.get("apiToken"),
  });

  const bearer = result?.permit || ""

  Cookies.set("bearer", bearer, { path: "/" });

  return bearer
}

const FastAPIRequestView:
  {
    get: ({ endpoint, params, bearer, extra }:
      {
        endpoint: any,
        params?: any,
        bearer?: any,
        token?: any,
        extra?: any,
        disablePrefixToken?: boolean;
      }) => any,
    post: ({ endpoint, data, params, bearer, token, extra }:
      {
        endpoint: any,
        data?: any,
        params?: any,
        bearer?: any,
        token?: any,
        extra?: any
        disablePrefixToken?: boolean;
      }) => any,
  } =
{
  get: async ({ endpoint, params, bearer, token, extra, disablePrefixToken }) => {
    const base = new BaseService()

    if (!bearer && !token) {
      bearer = await refreshPermit()
    }

    const fetch = async (bearer: string) => {
      if (bearer) {
        base.client.defaults.headers["Authorization"] = "Bearer " + bearer;
      } else if (token) {
        base.client.defaults.headers["Authorization"] = disablePrefixToken ? token : "token " + token
      }

      return await to(base.client.get(`${HOST}${endpoint}`, 
        {
          params: params ? { ...params } : {},
          ...extra
        }
      )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage))
    }

    let result = await fetch(bearer);

    if (typeof result[0]?.detail === "string" && result[0]?.detail?.includes("Permit")) {
      bearer = await refreshPermit()
      result = await fetch(bearer);
    }

    return [result[1], result[0], result[2]]
  },
  post: async ({ endpoint, data, params, bearer, token, extra,disablePrefixToken }) => {
    const base = new BaseService()

    if (!bearer && !token) {
      bearer = await refreshPermit()
    }

    if (data instanceof URLSearchParams) {
      base.client.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded"
    } else if (data instanceof FormData) {
      base.client.defaults.headers["Content-Type"] = "multipart/form-data"
    } else {
      data = { ...data }
    }

    const fetch = async (bearer: string) => {
      if (bearer) {
        base.client.defaults.headers["Authorization"] = "Bearer " + bearer;
      } else if (token) {
        base.client.defaults.headers["Authorization"] = disablePrefixToken ? token : "token " + token
      }

      return await to(base.client.post(`${HOST}${endpoint}`,
        data,
        {
          params: params ? { ...params } : {},
          ...extra
        }
      )
        .then(base.handleResponse)
        .catch(base.throwErrorMessage))
    }

    let result = await fetch(bearer);

    if (typeof result[0]?.detail === "string" && result[0]?.detail?.includes("Permit")) {
      bearer = await refreshPermit()
      result = await fetch(bearer);
    }

    return [result[1], result[0], result[2]]
  },
}

export default FastAPIRequestView

