import React, { useState, useMemo, useEffect } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Icon
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import moment from "moment";
import { useHistory, useLocation } from "react-router";

// UX
import DoctorBanner from "./DoctorBanner";
import ButtonAppointmentTypes from "./ButtonAppointmentTypes";
import DoctorReview from "./DoctorReview";

// Interface
import { State, Event } from "../MobSmartAppointmentInterface";
import { DoctorAppointmentActionType, AppointmentDoctorType } from "./Types";
import Skeleton from "@material-ui/lab/Skeleton";
import { FormattedMessage, useIntl } from "react-intl";
import { formattedDoctor } from "./SearchDoctor";
import { URLS } from "./Constants";

// Types
type DoctorProfileProps = {
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "loadingStatus"
  | "doctorProfile"
  | "loadingSkeleton"
  | "language"
  | "doctorSearchList"
  | "selectedHospital"
  | "appointmentCenterList"
  | "centerGroupList"
>;

type TabMenuType = "doctor-info" | "review";

type DPLocationType = {
  // data
  doctor: AppointmentDoctorType;
  storedState?:{
    doctor: AppointmentDoctorType;
  }
};

// Images
const IMAGES = {
  video_call: "/images/Appointment/video-call-outline-blue.png",
  hospital: "/images/Appointment/hospital-outline-blue.png",
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  primary: "var(--blue-bdms-color)",
  divider: "rgba(233, 233, 233, 1)",
  bg: "rgba(249, 249, 249, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
};

const descriptionStyles = {
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  backgroundColor: COLORS.bg,
  padding: "10px 16px",
  borderRadius: "8px",
  marginBottom: "0.95rem",
  minHeight: "46px",
  "& > div:nth-child(1)": {
    display: "grid",
    color: COLORS.grey,
    "& .see-more": {
      color: COLORS.primary,
      fontSize: ".875rem",
      fontWeight: "bold",
      textDecorationLine: "underline",
      marginTop: "0.25rem",
    },
  },
  "& > div:nth-child(2)": {
    color: COLORS.primary,
    fontSize: "1.125rem",
    fontWeight: "bold",
  },
};

const educationStyles = {
  display: "grid",
  fontWeight: "bold",
  marginTop: "0.75rem",
  gridTemplateColumns: "auto 1fr",
  "& > div:nth-child(2)": {
    display: "grid",
    fontSize: ".875rem",
    color: COLORS.light_grey,
    fontWeight: "normal",
    paddingLeft: "1.25rem",
    "& .title": {
      fontSize: "1rem",
      fontWeight: "bold",
      color: COLORS.primary,
    },
  },
} as CSSProperties;

const seeAllStyles = {
  color: COLORS.primary,
  fontSize: ".875rem",
  fontWeight: "bold",
  marginTop: "0.05rem",
  display: "flex",
  alignItems: "center",
  textDecorationLine: "underline",
  "& svg": {
    margin: "0 0 0 5px",
  },
} as CSSProperties;

const useStyles = makeStyles(() => ({
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: COLORS.grey,
    margin: "1.15rem 0 0.85rem",
  },
  box_title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "1.15rem 0 1rem",
    "& div[class*=title]": {
      margin: 0,
    },
  },
  sub_title: {
    fontWeight: "bold",
    marginBottom: "0.85rem",
    color: COLORS.primary,
  },
  divider: {
    borderBottom: `1px solid ${COLORS.divider}`,
    width: "100%",
    margin: "1.5rem 0",
  },
  label: {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.75rem",
    fontWeight: "bold",
    color: COLORS.primary,
    "& img.icon": {
      width: "20px",
      marginRight: "0.55rem",
    },
  },
  description: descriptionStyles,
  see_all: seeAllStyles,
  education: educationStyles,
}));

const BUTTON_ACTIONS = {
  onsite: "_on-site",
  online: "_online",
};

const DOCTOR_PROFILE = "DoctorProfile";



const DoctorProfile = (props: DoctorProfileProps) => {
  const classes = useStyles();
  const location = useLocation<DPLocationType>();
  const history = useHistory();
  const intl = useIntl();
  const params = new URLSearchParams(window.location.search);
  const app = params.get("app");
  const MOB_APP = `app=${app}`;
  const doctorId = params.get("doctor")
  const [tab, setTab] = useState<TabMenuType>("doctor-info");

  const doctorItems = useMemo(() => {
    return (props.doctorSearchList?.items || []).map((item) => ({
      ...item,
      is_doctor: true,
    }));
  }, [props.doctorSearchList]);

  const centerGroupItems = useMemo(() => {
    const doctorItem = doctorItems;

    const centers = (doctorItem
    ).flatMap((item) => item.centers);
    const filterMedProgram = centers.filter((item:any) => !!item)
    let groups = filterMedProgram.reduce((result, item:any) => {
      const key = item.center_group_id;
      const data = {
        id: item.center_id,
        slug: item.center_slug,
      };

      if (!result[key]) {
        const group = props.centerGroupList?.find(
          (acc) => acc.id === parseInt(item.center_group_id)
        );

        result[key] = {
          id: key,
          slug: item.center_group_slug,
          name: group?.name,
          image: group?.icon_url,
          items: [data],
        };
      } else {
        result[key].items.push(data);
      }

      return result;
    }, {} as any);

    groups = Object.values(groups).map((item: any) => {
      let unique = Array.from(
        new Map(item.items.map((acc: any) => [acc["id"], acc])).values()
      );

      unique = unique.map((acc: any) => {
        const center = props.appointmentCenterList?.find(
          (o) => o.id === parseInt(acc.id)
        );

        return { ...acc, name: center?.name, image: center?.image_url };
      });

      return {
        ...item,
        items: unique,
      };
    });

    return groups as any[];
  }, [
    props.centerGroupList,
    props.appointmentCenterList,
    doctorItems,
  ]);

  // Memo Effect
  const locState = useMemo(() => {   
    if (doctorId) {
      let doctor = {}
      if (doctorItems.length > 0 && centerGroupItems) {
        doctor = {doctor:formattedDoctor(centerGroupItems,doctorItems[0])}
      }

      return Object.keys(location.state?.storedState || {}).length ? location.state?.storedState || {} : doctor;
    } else {
      return location.state || {};
    
    }
  }, [location.state,doctorId, doctorItems,centerGroupItems]);

  // Effect
  useEffect(() => {
    if (locState.doctor?.id) {
      props.onEvent({
        message: "GetDoctorProfile",
        params: { id: locState.doctor?.id || doctorId },
      });
    }

    if (doctorId && !props.doctorSearchList) {
      props.onEvent({
        message: "HandleSearchListDoctor",
        params: {
          doctorId: params.get("doctor") || undefined,
          emptyClear: true,
          hospitalCode: locState.hospital?.code || props.selectedHospital?.code,
        },
      });
    }
  }, [locState.doctor,doctorId]);

  useEffect(() => {
    if (!props.appointmentCenterList?.length && doctorId) {
      props.onEvent({
        message: "GetListAppointmentCenter",
        params: { lang: intl.locale },
      });
    }
  }, []);

  useEffect(() => {
    if (!props.centerGroupList?.length && doctorId) {
      props.onEvent({
        message: "GetListCenterGroup",
        params: { lang: intl.locale },
      });
    }
  }, []);

  // Memo
  const score = useMemo(() => {
    const doctor = locState.doctor;

    return {
      star: doctor?.score || 0,
      review: doctor?.review || 0,
    };
  }, [locState.doctor]);

  const divisions = useMemo(() => {
    const [onsite, online] = (locState.doctor?.divisions || []).reduce(
      (result, item) => {
        if (item.is_telemed) {
          result[1].push(item);
          result[0].push({ ...item, is_telemed: false });
        } else {
          result[0].push(item);
        }

        return result;
      },
      [[], []] as any[][]
    );

    return { onsite, online };
  }, [locState.doctor]);

  // Handler
  const handleChangeTab = (newTab: TabMenuType) => () => {
    setTab(newTab);
  };

  const handleAppointmentAction = (
    action: DoctorAppointmentActionType,
    loading: string
  ) => {
    if (!locState.doctor?.is_telemed && action === "online") {
       console.log("disabledOnline :", !locState.doctor?.is_telemed )
    } else {
      props.onEvent({
        message: "HandleDoctorAppointmentAction",
        params: {
          history,
          action,
          doctor: locState.doctor || {},
          card: loading,
        },
      });
    }
  };

  const handleCheckGoBack = () =>{
    history.replace(`${URLS.SELECT_CENTERGROUP}?${MOB_APP}&backToMain=true`)
  }

  return (
    <DoctorBanner
      titleName={locState.doctor?.full_name}
      hospitalName={locState.doctor?.hospital_name}
      score={score}
      experience={locState.doctor?.experience}
      headerImage={locState.doctor?.image || props.doctorProfile?.image}
      bottom={
        <ButtonAppointmentTypes
          disabledOnline={!locState.doctor?.is_telemed}
          disabledOnSite={!locState.doctor?.is_onsite}
          loadingStatus={props.loadingStatus}
          // style
          style={{ marginTop: tab === "doctor-info" ? "-1.45rem" : "-1rem" }}
          // callback
          onButtonAction={handleAppointmentAction}
        />
      }
      onGoBack={doctorId ? handleCheckGoBack : undefined}
    >
      <div>
        <div className="smart-tabs-custom">
          <div
            aria-hidden="true"
            className={tab === "doctor-info" ? "active" : ""}
            onClick={handleChangeTab("doctor-info")}
          >
            <FormattedMessage id="bplusClinicKey1252" />
          </div>
          <div
            aria-hidden="true"
            className={tab === "review" ? "active" : ""}
            onClick={handleChangeTab("review")}
          >
            <FormattedMessage id="bplusClinicKey1250" />
          </div>
        </div>

        {/* แท็บข้อมูลแพทย์ */}
        {tab === "doctor-info" ? (
          <>
            <div className={classes.title}>
              <FormattedMessage id="bplusClinicKey1258" />
            </div>
            <div className={classes.sub_title}>
              {locState.doctor?.specialties}
            </div>
            <div className={classes.divider}></div>
            <div className={classes.title}>
              <FormattedMessage id="bplusClinicKey1259" />
            </div>
            {!!divisions.online.length && (
              <>
                <div className={classes.label}>
                  <img className="icon" src={IMAGES.video_call}></img>
                  <label>
                    <FormattedMessage id="bplusClinicKey954" />
                  </label>
                </div>
                {divisions.online.map((division) => (
                  <div
                    key={`description-${division.division_id}`}
                    className={classes.description}
                  >
                    <div>
                      <div>{division.division_name}</div>
                      <div className="see-more">
                        <FormattedMessage id="bplusClinicKey1149" />
                      </div>
                    </div>
                    <div>
                      {!!division.doctor_fee && <>฿ {division.doctor_fee}</>}
                    </div>
                  </div>
                ))}
              </>
            )}

            {!!divisions.onsite.length && (
              <>
                <div className={classes.label}>
                  <img className="icon" src={IMAGES.hospital}></img>
                  <label>
                    <FormattedMessage id="bplusClinicKey1132" />
                  </label>
                </div>

                {divisions.onsite.map((division) => (
                  <div
                    key={`description-${division.division_id}`}
                    className={classes.description}
                  >
                    <div>
                      <div>{division.division_name}</div>
                      <div className="see-more">
                        <FormattedMessage id="bplusClinicKey1149" />
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))}

                <div className={classes.description}>
                  <div>
                    <div>
                      <FormattedMessage id="bplusClinicKey1257" />
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className={classes.divider}></div>

            {!!props.doctorProfile?.educations?.length && (
              <>
                <div className={classes.box_title}>
                  <div className={classes.title}>
                    <FormattedMessage id="bplusClinicKey1256" />
                  </div>
                  <div className={classes.see_all}>
                    <label>
                      <FormattedMessage id="bplusClinicKey1253" />
                    </label>
                    <KeyboardArrowDownRoundedIcon />
                  </div>
                </div>

                {props.loadingSkeleton ? (
                  <>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Skeleton width="15%" height="50%" />
                      <div style={{ width: "100%" }}>
                        <Skeleton width="50%" />
                        <Skeleton width="30%" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {(props.doctorProfile?.educations || []).map((item) => (
                      <div
                        key={"item-" + item.id}
                        className={classes.education}
                      >
                        <div>
                          {item.graduated_date
                            ? moment(item.graduated_date).format("YYYY")
                            : " "}
                        </div>{" "}
                        <div>
                          <div className="title">{item.name}</div>
                          <div>
                            {item.graduated_from}, {item.country}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </>
            )}

            <div className={classes.divider}></div>

            {!!props.doctorProfile?.languages?.length && (
              <>
                <div className={classes.title}>
                  <FormattedMessage id="bplusClinicKey1254" />
                </div>
                {props.loadingSkeleton ? (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Skeleton width="15%" />
                    <Skeleton width="20%" />
                  </div>
                ) : (
                  <div style={{ color: COLORS.light_grey }}>
                    {(props?.doctorProfile?.languages || [])
                      .map((lang) => lang.name)
                      .join(", ")}
                  </div>
                )}
                <div className={classes.divider}></div>
              </>
            )}

            <div className={classes.title}>
              <FormattedMessage id="bplusClinicKey1255" />
            </div>
            <div style={{ color: COLORS.light_grey, fontWeight: "bold" }}>
              {props.loadingSkeleton ? (
                <div style={{ display: "flex", gap: "10px" }}>
                  <Skeleton width="25%" />
                  <Skeleton width="15%" />
                  <Skeleton width="20%" />
                </div>
              ) : (
                <div style={{ color: COLORS.light_grey }}>
                  {(props?.doctorProfile?.extra?.clinical_interests || [])
                    .map((interests: any) =>
                      props.language === "en-US"
                        ? interests.name
                        : interests.name_local
                    )
                    .join(", ")}
                </div>
              )}
            </div>
            <div className={classes.divider}></div>
          </>
        ) : (
          // แท็บรีวิว
          tab === "review" && (
            <DoctorReview review={props.doctorProfile?.review || {}} />
          )
        )}
      </div>
    </DoctorBanner>
  );
};

export default React.memo(DoctorProfile);
