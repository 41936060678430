// Autogenerated by utils/python/genmain.py
import React from "react";
import TabPath from "react-lib/frameworks/TabPath";
import MarketingSegment from "../post/MarketingSegment";
import MarketingAnalytics from "../post/MarketingAnalytics";
import PostEx from "../post/PostEx";
import PromotionEX from "../post/PromotionEX";
import {MarketingAnalyticsType} from "../post/MarketingAnalyticsInterface";

import MenuIcon from "./MenuIcon";
// ui
import { DM_RESULT, DM_SUB_RESULT } from "../ManageInterface";
import { State } from "../ManageInterface";

type MainMarketingProps = {
  // function
  onEvent: any;
  setProp: any;
  // common data
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  selectedDivision?: any;
  clinic?: string;
  organizationList?: any[];
  selectedOrganizationCode?: any;
  showSegment?: boolean;
  // Segmentation
  loadingMarketingSegment?: boolean;
  loadingMarketingPatient?: boolean;
  loadingMarketingAnalytics?: boolean;
  filterMarketingPatient?: any;
  marketingSegmentList?: any;
  marketingPatientList?: any;
  marketingSegmentAgeGender?: any;
  marketingSegmentTopPost?: any[];
  marketingSegmentTopPromotion?: any[];
  marketingCreatePatient?: any;
  marketingAnalytics?: MarketingAnalyticsType;
  marketingAnalyticsSegment?: string[];
  patientAnalyticsDetail?: any;
  loadingAnalyticsSegment?: any;
  loadingPatientAnalytics?: any;
  analyticPatientList?:any;
} & Pick<State,"doctorOptionsLoading" | "doctorOptions" >;

const MainMarketingInitial: MainMarketingProps = {
  // function
  onEvent: () => null,
  setProp: () => null,
  // common data
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  selectedDivision: {},
  clinic: "",
  // Segmentation
  loadingMarketingSegment: false,
  loadingMarketingPatient: false,
  loadingMarketingAnalytics: false,
  filterMarketingPatient: {},
  marketingSegmentList: {},
  marketingPatientList: {},
  marketingSegmentAgeGender: {},
  marketingSegmentTopPost: [],
  marketingSegmentTopPromotion: [],
  marketingCreatePatient: null,
  marketingAnalytics: {},
  patientAnalyticsDetail: {},
  loadingAnalyticsSegment: false,
  analyticPatientList: {}
};

const DM_LEFT_MENU = [
  {
    result: DM_RESULT.DM,
    subResults: [
      DM_SUB_RESULT.NO_COMPLICATION,
      DM_SUB_RESULT.RISK_TO_CVD,
      DM_SUB_RESULT.UNCLASSIFIED,
    ],
  },
  {
    result: DM_RESULT.PRE_DM,
    subResults: [DM_SUB_RESULT.NO_COMPLICATION, DM_SUB_RESULT.RISK_TO_CVD],
  },
  {
    result: DM_RESULT.DM_SURVEILLANCE,
    subResults: [DM_SUB_RESULT.NO_COMPLICATION, DM_SUB_RESULT.RISK_TO_CVD],
  },
];

const MainMarketing: React.FC<MainMarketingProps> = (props: any) => {
  // const handleTabChange = (index: number) => {
  //   if (index === 0 || index === 1) {
  //     props.setProp("leftMenuSelected", "patient_segment");
  //   } else if (index === 5) {
  //     props.setProp("leftMenuSelected", "user_management");
  //   }
  // };

  return (
    <div className="MainDm-tabPath" style={{ height: "100%" }}>
      {/* 94vh */}
      <TabPath
        selectedTabIndex={0}
        level={1}
        history={props.history}
        tabWidth={props.showPatientDM ? "0" : "5.7vw"}
        onTabChange={(index: any) => {
          if (!props.selectedDivision) {
            alert("กรุณาเลือก แผนก ก่อนการใช้งาน")
          }
        }}
        data={[
          {
            name: (
              <MenuIcon
                label="Segmentation"
                icon="/images/expansion/carbon-user-activity.png"
              />
            ),
            key: "segmentation",
            render: () => (
              <div style={{ height: "100%" }}>
                <MarketingSegment
                  // function
                  onEvent={props.onEvent}
                  setProp={props.setProp}
                  // data
                  errorMessage={props.errorMessage}
                  successMessage={props.successMessage}
                  loadingStatus={props.loadingStatus}
                  loadingMarketingSegment={props.loadingMarketingSegment}
                  loadingMarketingPatient={props.loadingMarketingPatient}
                  selectedDivision={props.selectedDivision}
                  filterMarketingPatient={props.filterMarketingPatient}
                  marketingSegmentList={props.marketingSegmentList}
                  marketingPatientList={props.marketingPatientList}
                  marketingSegmentAgeGender={props.marketingSegmentAgeGender}
                  marketingSegmentTopPost={props.marketingSegmentTopPost}
                  marketingSegmentTopPromotion={
                    props.marketingSegmentTopPromotion
                  }
                  marketingCreatePatient={props.marketingCreatePatient}
                  showSegment={props.showSegment}
                />
              </div>
            ),
          },
          {
            name: (
              <MenuIcon
                label="Promotion"
                icon="/images/expansion/fontisto_shopping-sale.png"
              />
            ),
            key: "promotion",
            render: () => (
              <div>
                <PromotionEX
                  onEvent={props.onEvent}
                  marketingPromotionList={props.marketingPromotionList}
                  marketingPromotionIndex={props.marketingPromotionIndex}
                  clinicalSegment={props.clinicalSegment}
                  loadingMainPromotion={props.loadingMainPromotion}
                  marketingSegmentList={props.marketingSegmentList}
                  marketingPromotionId={props.marketingPromotionId}
                  divisionHasUser={props.divisionHasUser}
                  marketingPromotionChart={props.marketingPromotionChart}
                  selectedDivisionId={props.selectedDivisionId}
                />
                {/* <FormattedMessage id="bplusClinicKey401" /> */}
              </div>
            ),
          },
          {
            name: (
              <MenuIcon label="Post" icon="/images/expansion/mdi_post.png" />
            ),
            key: "post_ex",
            render: () => (
              <PostEx
                // function
                onEvent={props.onEvent}
                marketingPostList={props.marketingPostList}
                marketingPostIndex={props.marketingPostIndex}
                clinicalSegment={props.clinicalSegment}
                loadingMainPost={props.loadingMainPost}
                marketingSegmentList={props.marketingSegmentList}
                marketingPostId={props.marketingPostId}
                divisionHasUser={props.divisionHasUser}
                marketingPostChart={props.marketingPostChart}
                selectedDivisionId={props.selectedDivisionId}
                doctorOptions={props.doctorOptions}
                doctorOptionsLoading={props.doctorOptionsLoading}
              />
            ),
          },
          {
            name: (
              <MenuIcon
                label="Analytics"
                icon="/images/expansion/icon_analytics.png"
              />
            ),
            key: "Analytics",
            render: () => (
              <MarketingAnalytics
                // function
                onEvent={props.onEvent}
                setProp={props.setProp}
                // data
                patientAnalyticsDetail={props.patientAnalyticsDetail}
                selectedOrganizationCode={props.selectedOrganizationCode}
                organizationList={props.organizationList}
                loadingMarketingAnalytics={props.loadingMarketingAnalytics}
                loadingPatientAnalytics={props.loadingPatientAnalytics}
                loadingAnalyticsSegment={props.loadingAnalyticsSegment}
                loadingMarketingPatient={props.loadingMarketingPatient}
                marketingAnalytics={props.marketingAnalytics}
                marketingAnalyticsSegment={props.marketingAnalyticsSegment}
                analyticPatientList={props.analyticPatientList}
                showSegment={props.showSegment}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

MainMarketing.defaultProps = MainMarketingInitial;

export default React.memo(MainMarketing);
