import React, { CSSProperties, useEffect, useState, useMemo } from "react";
import { Card, Grid, Header, Icon, Image, Divider } from "semantic-ui-react";
// Mui
import { makeStyles, Button as MuiButton } from "@material-ui/core";

import moment from "moment";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "../clinic/SnackMessage";

// UI
import MobSkeletonPHR from "./MobSkeletonPHR";
import TabMenu from "../history/TabMenu";
import HospitalSearchBox from "../medication/HospitalSearchBox";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
} from "../MobClinicInterface";

// Use styles
const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
};

const useStyles = makeStyles(() => ({
  download: {
    color: "#767676 !important",
    "&.active": {
      color: `${COLORS.primary} !important`,
    },
  },
  header: {
    width: "92% !important",
    "& > .ui.segment.inverted.blue": {
      backgroundColor: `${COLORS.primary} !important`,
      padding: "1.2rem",
    },
  },
  button: {
    width: "100%",
    margin: "5px 0",
    fontSize: "16px",
    textTransform: "unset",
    backgroundColor: COLORS.primary,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: COLORS.primary,
    },
    borderRadius: "8px",
    padding: "6.5px 0",
    "&.btn-outline": {
      border: `1px solid ${COLORS.primary}`,
      color: COLORS.primary,
      backgroundColor: "transparent",
      "&:hover": {
        border: `1px solid ${COLORS.primary}`,
        backgroundColor: "transparent",
      },
      marginTop: 0,
    },
  },
}));

const styles = {
  card: {
    width: "100%",
    fontSize: "15px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    lineHeight: "1.5em",
    borderRadius: "10px",
  } as CSSProperties,
  noMargin: {
    margin: "1px 0",
  } as CSSProperties,
  noPadding: {
    padding: "0px 0",
  } as CSSProperties,
  checkup_icon: {
    width: "50px",
    height: "50px",
    display: "flex",
    borderRadius: "25px",
    alignItems: "center",
    justifyContent: "center",
    background: "#CCDAED",
  } as CSSProperties,
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px 20px",
    color: COLORS.primary,
  } as CSSProperties,
};

type CheckupProps = {
  onEvent: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  checkupList?: any[];
  apiToken?: string;
  selectedHospital?: any;
  hospitalList?: any[];
  firstLoadingHospital?: boolean;
  loadingSearchHospitalFilter?: boolean;
  textSearchHospitalFilter?: string;
} & WrappedComponentProps;

const CheckupInitial: Omit<CheckupProps, "intl"> = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  checkupList: [],
  selectedHospital: null,
  hospitalList: [],
  firstLoadingHospital: false,
  loadingSearchHospitalFilter: false,
  textSearchHospitalFilter: "",
};

const Checkup: React.FC<CheckupProps> = (props) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [preferred, setPreferred] = useState<string>("Preferred");
  const [checkupList, setCheckupList] = useState<any[]>([]);
  const [searchHospital, setSearchHospital] = useState<string>("");

  const classes = useStyles();

  useEffect(() => {
    if (props.selectedHospital != null) {
      props.onEvent({
        message: "handleGetCheckupList",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  }, [props.selectedHospital]);

  useEffect(() => {
    setCheckupList((props.checkupList || []).filter((item) => item.reportId));
  }, [props.checkupList]);

  useEffect(() => {
    const items: any[] = (props.checkupList || []).filter(
      (item) => item.reportId
    );
    const list = items.filter((item) =>
      item.hospital_name?.toLowerCase()?.includes(searchHospital.toLowerCase())
    );

    setCheckupList(list);
  }, [searchHospital]);

  const hospitalList = useMemo(() => {
    // const items: any[] = (props.checkupList || []).filter(
    //   (item) => item.reportId
    // );
    // const arrayFlat = items.map((item) => {
    //   const name = item.hospital_name
    //     ? item.hospital_name
    //     : props.selectedHospital?.name
    //     ? props.selectedHospital.name
    //     : "";
    //   return { name, code: name };
    // });
    if (props.hospitalList !== undefined) {
      return Array.from(
        new Map(
          props.hospitalList.map((item: any) => [item["name"], item])
        ).values()
      );
    } else {
      const items: any[] = (props.checkupList || []).filter(
        (item) => item.reportId
      );
      const arrayFlat = items.map((item) => {
        const name = item.hospital_name
          ? item.hospital_name
          : props.selectedHospital?.name
          ? props.selectedHospital.name
          : "";
        return { name, code: name };
      });
    }
  }, [props.checkupList, props.selectedHospital, props.hospitalList]);

  const handleOpenModConfirm = (item: any) => {
    setSelectedFile(item);
    setOpenModConfirm(true);
  };

  const handleCloseModConfirm = () => {
    setSelectedFile(null);
    setOpenModConfirm(false);
  };

  const handleDownloadFile = () => {
    const url =
      "apis/PRX/v3/checkup_file/?chkup_id=" +
      selectedFile.reportId +
      "&hospital=" +
      props.selectedHospital?.code;
    const en = selectedFile.en.replaceAll("-", "").toLowerCase();
    const filename = `checkup_${en}.pdf`;

    props.onEvent({
      message: "handleMobileDownloadCheckup",
      params: { url: url, filename: filename },
    });

    handleCloseModConfirm();
  };

  const closeSnackMessage = () => {
    props.onEvent({ message: "closeSnackMessage" });
  };

  const handleChangePreferred = (preferred: string) => {
    setPreferred(preferred);
    if (preferred === "All") {
      props.onEvent({
        message: "handleGetCheckupList",
        params: { hospital: "all" },
      });
    } else {
      props.onEvent({
        message: "handleGetCheckupList",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  };

  const handleSearch = (value: string) => {
    setSearchHospital(value);
  };

  console.log("props", props);
  return (
    <div style={{ padding: "15px" }}>
      {/* <Dimmer
        active={props.loadingStatus}
        style={{ marginTop: "50px" }}
        inverted
      >
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer> */}

      <SnackMessage
        onEvent={props.onEvent}
        onClose={closeSnackMessage}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div style={styles.parentbox}>
        <div style={{ marginBottom: "20px" }}>
          <div style={styles.title}>
            {" "}
            <FormattedMessage id={"bplusClinicKey935"} />
          </div>
          <Divider
            style={{
              background: "red",
              marginTop: "5px",
              marginBottom: "5px",
            }}
            variant="middle"
          />
        </div>

        <TabMenu
          tab={preferred}
          leftTitle="Preferred"
          rightTitle="All"
          // config
          paddingStyle="0"
          marginStyle="0 0 15px"
          rightDisabled={props.loadingStatus}
          leftDisabled={props.loadingStatus}
          // callback
          onChange={handleChangePreferred}
        />
      </div>

      {props.loadingStatus ? (
        <MobSkeletonPHR
          margin="10px 0"
          divider={false}
          hideFixedSkeleton={true}
          lineHeight="8px"
        />
      ) : (
        <>
          {preferred === "All" && (
            <HospitalSearchBox
              hospitalList={hospitalList}
              margin={0}
              width="88%"
              onSearchChange={handleSearch}
            />
          )}

          {!checkupList?.length && !props.loadingStatus && (
            <Header as="h3" color="grey" style={{ textAlign: "center" }}>
              {props.intl.formatMessage({ id: "common.no_data_checkup" })}
            </Header>
          )}

          {checkupList.map((item: any, index: number) => (
            <Card key={index} style={styles.card}>
              <Card.Content>
                <Grid verticalAlign="middle">
                  <Grid.Column width={3}>
                    <div style={styles.checkup_icon}>
                      <Image
                        src="/images/Feed/icon_bluebdms_checkup.png"
                        style={{ width: "28px" }}
                      />
                    </div>
                  </Grid.Column>
                  <Grid.Column width={13}>
                    <Grid style={{ margin: "3px 0px" }}>
                      <Grid.Row style={styles.noPadding}>
                        <Header
                          as="h3"
                          style={{
                            ...styles.noMargin,
                            color: COLORS.primary,
                          }}
                        >
                          {item.hospital_name
                            ? item.hospital_name
                            : props.selectedHospital?.name
                            ? props.selectedHospital.name
                            : ""}
                        </Header>
                      </Grid.Row>
                      <Grid.Row style={styles.noPadding}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div>
                            <div style={{ margin: "10px 0" }}>
                              <Header
                                as="h5"
                                color="grey"
                                style={styles.noMargin}
                              >
                                <FormattedMessage id="bplusClinicKey176" />{" "}
                                {item.en}
                              </Header>
                            </div>
                            <div style={{ margin: "10px 0" }}>
                              <Header
                                as="h5"
                                color="grey"
                                style={styles.noMargin}
                              >
                                <FormattedMessage id="bplusClinicKey111" />{" "}
                                <span
                                  style={{
                                    color: COLORS.primary,
                                  }}
                                >
                                  {item?.doccumentTime
                                    ? moment(
                                        item?.doccumentTime,
                                        `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                                      ).format("DD-MM-YYYY")
                                    : "-"}
                                </span>
                              </Header>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-end",
                              marginBottom: "10px",
                            }}
                          >
                            <button
                              disabled={!item?.reportId}
                              style={{
                                border: "none",
                                background: "none",
                              }}
                              onClick={() => {
                                handleOpenModConfirm(item);
                              }}
                            >
                              <Icon.Group size="big">
                                <Icon
                                  name="file outline"
                                  className={`${classes.download}${
                                    item?.reportId ? " active" : ""
                                  }`}
                                />
                                <Icon
                                  corner="bottom right"
                                  name="download"
                                  className={`${classes.download}${
                                    item?.reportId ? " active" : ""
                                  }`}
                                />
                              </Icon.Group>
                            </button>
                          </div>
                        </div>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          ))}

          <ModConfirm
            openModal={openModConfirm}
            titleName={props.intl.formatMessage({
              id: "download.download",
            })}
            content={props.intl.formatMessage({
              id: "download.download_pdf_info_checkup",
            })}
            approveButton={
              <MuiButton
                className={`${classes.button} btn-outline`}
                variant="contained"
                onClick={handleDownloadFile}
              >
                {props.intl.formatMessage({
                  id: "download.download",
                })}
              </MuiButton>
            }
            denyButtonText=""
            buttonClassName={`centered seven wide`}
            className={classes.header}
            // callback
            onDeny={handleCloseModConfirm}
            onCloseWithDimmerClick={handleCloseModConfirm}
          />
        </>
      )}
    </div>
  );
};

Checkup.defaultProps = CheckupInitial;

export default React.memo(injectIntl(Checkup));
