import React from 'react'
import PreferredEditorHome from "./PreferredEditorHome";
import PreferredEditorAppointment from "./PreferredEditorAppointment";
import { PREFERRED_MODE } from './Preferred';

const PreferredEditor = (props: any) => {
  return (
    props.mode === PREFERRED_MODE.HOME ? <PreferredEditorHome {...props} /> : <PreferredEditorAppointment {...props} />
  )
}

export default PreferredEditor