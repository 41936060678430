import React, {
  MutableRefObject,
  useEffect,
  useRef,
  ChangeEvent,
  useMemo,
  useState,
  useCallback,
} from "react";
// CSS
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import { Button as MuiButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
// Select
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// DatePicker
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// Icon
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";

import moment from "moment";
import { useIntl } from "react-intl";

// Interface
import { Event, State } from "../ManageInterface";
import { PromotionData } from "./PromotionExInterface";

// UI
import { PostPreview } from "./PostEx";
import PostTab from "./PostTab";
import PostList from "./PostList";
import PostChart from "./PostChart";
import PostSearchBox from "./PostSearchBox";
import PostSegment from "./PostSegment";
import PostFormByType from "./PostFormByType";

type PromotionEXProps = {
  // callback
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "marketingPromotionList"
  | "marketingPromotionIndex"
  | "clinicalSegment"
  | "loadingMainPromotion"
  | "marketingSegmentList"
  | "marketingPromotionId"
  | "divisionHasUser"
  | "marketingPromotionChart"
  | "selectedDivisionId"
>;

export type PromotionEditorProps = {
  // data
  detail: PromotionData | null;
  // callback
  onChange?: (data: Partial<PromotionData>) => any;
  onSave: (params: { id: any; callback?: () => any }) => any;
} & Pick<
  PromotionEXProps,
  "onEvent" | "clinicalSegment" | "marketingSegmentList" | "divisionHasUser"
>;

const ICONS = {
  edit: "/images/expansion/bx_bx-edit.png",
  carbon_analytics: "/images/expansion/carbon_analytics.png",
  user_activity: "/images/expansion/carbon_user-activity.png",
};

const COLORS = {
  red: "#DA0000",
};

const styles = {
  fontbold: {
    fontWeigt: "bold",
    margin: "0px 10px",
  },
  fontcenter: {
    display: "flex",
    justifyContent: "center",
  },
};

const useStyles = makeStyles(() => ({
  banners: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "25px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .MuiButton-root.MuiButton-contained": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const PromotionEX: React.FunctionComponent<PromotionEXProps> = (props) => {
  const [title, setTitle] = useState<string>("");
  const [promotionList, setPromotionList] = useState<PromotionData[]>([]);
  const [activeTab, setActiveTab] = useState(0);
  const [filterSelected, setFilterSelected] = useState<string>("all");
  const [firstSelect, setFirstSelect] = useState<boolean>(false);

  useEffect(() => {
    props.onEvent({ message: "GetListMarketingPromotion", params: {} });
  }, []);

  const displayPromotionList = useMemo(() => {
    return (props.marketingPromotionList || [])
      .filter((item) => item.id !== null)
      ?.filter((item) => !item.delete);
  }, [props.marketingPromotionList]);

  useEffect(() => {
    const filter = displayPromotionList
      ?.filter((all: any) => all.divisionId === props.selectedDivisionId)
      ?.filter((item: any) => {
        if (filterSelected === "active") {
          return item?.active === true;
        } else if (filterSelected === "inactive") {
          return item?.active === false;
        } else {
          return item?.active !== null;
        }
      })
      ?.filter((item) =>
        item.title?.toLowerCase().includes(title?.toLowerCase())
      );

    setPromotionList(filter);

    if (
      (props.marketingPromotionIndex === 0 &&
        filter[0]?.id !== displayPromotionList[0]?.id &&
        props.marketingPromotionId) ||
      firstSelect
    ) {
      setFirstSelect(false);
      if (filter[0] !== undefined) {
        handleClickItem(filter[0], 0);
      } else {
        handleAddPromotion();
      }
    }
  }, [
    props.marketingPromotionList,
    title,
    filterSelected,
    props.selectedDivisionId,
  ]);

  const activeIndex = useMemo(() => {
    const index = props.marketingPromotionIndex || 0;

    return props.marketingPromotionList?.[index] !== null;
  }, [props.marketingPromotionList, props.marketingPromotionIndex]);

  const handleClickItem = (data: PromotionData, index: number) => {
    props.onEvent({
      message: "HandleSetMarketingPromotionIndex",
      params: {
        index: displayPromotionList.findIndex((item) => item.id === data.id),
      },
    });
  };

  const handleChange = (data: Record<string, any>) => {
    props.onEvent({
      message: "HandleChangeMarketingPromotion",
      params: { data },
    });
  };

  const handleAddPromotion = () => {
    props.onEvent({ message: "HandleAddNewMarketingPromotion", params: {} });
  };

  const handleSavePromotion = (params: { id: any; callback?: () => any }) => {
    props.onEvent({
      message: "SaveMarketingPromotion",
      params,
    });
  };

  const handleSearchList = (search: string) => {
    setTitle(search);
  };

  const handleGetChart = () => {
    if (activeTab === 2) {
      props.onEvent({
        message: "HandleGetPromotionChart",
        params: {
          id:
            props.marketingPromotionList?.[props.marketingPromotionIndex || 0]
              ?.id || null,
        },
      });
    }
  };

  const handleSetActiveTab = (item: any, index: number) => {
    setActiveTab(index);
  };

  const handleFilterActive = (select: string) => {
    setFirstSelect(true);
    setFilterSelected(select);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "320px 1fr 375px",
          height: "calc(100vh - 47px)",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            gridColumn: 1,
            paddingTop: "10px",
            borderRight: "1px solid rgb(238, 238, 238)",
          }}
        >
          <PostList
            onEvent={props.onEvent}
            list={promotionList}
            activeId={props.marketingPromotionId}
            disabled={!activeIndex}
            titleKey="title"
            dateKey="available_at"
            onClickItem={handleClickItem}
            onAdd={handleAddPromotion}
            showFilter={true}
            loadingStatus={props.loadingMainPromotion}
            onSearch={handleSearchList}
            filterActive={filterSelected}
            onFilterActive={handleFilterActive}
          />
        </div>
        <div className="PromotionEditor">
          <PostTab
            initialActive={0}
            onClickTab={handleSetActiveTab}
            disableMenu={false}
            panes={[
              {
                key: "editor",
                image: ICONS.edit,
                render: () => (
                  <PromotionEditor
                    onEvent={props.onEvent}
                    onChange={handleChange}
                    detail={
                      props.marketingPromotionList?.[
                        props.marketingPromotionIndex || 0
                      ] || null
                    }
                    clinicalSegment={props.clinicalSegment}
                    marketingSegmentList={props.marketingSegmentList}
                    divisionHasUser={props.divisionHasUser}
                    onSave={handleSavePromotion}
                  />
                ),
              },
              {
                key: "segment",
                image: ICONS.user_activity,
                render: () => (
                  <PostSegment
                    onEvent={props.onEvent}
                    id={
                      props.marketingPromotionList?.[
                        props.marketingPromotionIndex || 0
                      ]?.id
                    }
                    marketingSegmentList={props.marketingSegmentList}
                    manageSegmentList={
                      props.marketingPromotionList?.[
                        props.marketingPromotionIndex || 0
                      ]?.segment || []
                    }
                    onChange={handleChange}
                    onSave={handleSavePromotion}
                  />
                ),
              },
              {
                key: "chart",
                image: ICONS.carbon_analytics,
                render: () => (
                  <PostChart
                    onEvent={props.onEvent}
                    data={props.marketingPromotionChart}
                    onGetChart={handleGetChart}
                    id={
                      props.marketingPromotionList?.[
                        props.marketingPromotionIndex || 0
                      ]?.id || null
                    }
                  />
                ),
              },
            ]}
          />
        </div>
        <div
          style={{
            gridColumn: 3,
          }}
        >
          <PostPreview
            detail={
              props.marketingPromotionList?.[
                props.marketingPromotionIndex || 0
              ] || null
            }
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

/* TODO ------------------- PromotionEditor ------------------ */
const PromotionEditor: React.FunctionComponent<PromotionEditorProps> = (
  props
) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const bannersRef = useRef() as MutableRefObject<HTMLInputElement>;
  const photoRef = useRef() as MutableRefObject<HTMLInputElement>;
  const [isSave, setIsSave] = useState<boolean>(false);
  const [popup, setPopup] = useState<boolean>(false);
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
  const [saveActive, setSaveActive] = useState<boolean | undefined>(false);
  const [saveDetailId, setSaveDetailId] = useState<string | null | undefined>(
    ""
  );

  const classes = useStyles();

  // const handleGetHospitalOptions = useCallback(
  //   (params: { searchText: string }) => {
  //     return props.onEvent({
  //       message: "HandleGetHospitalOptions",
  //       params: { search: params.searchText },
  //     });
  //   },
  //   []
  // );
  useEffect(() => {
    if (isSave) {
      handleCallSave();
    }
    if (isSaveActive && props.detail?.id === saveDetailId) {
      handleSave();
    } else {
      setSaveDetailId("");
      setIsSaveActive(false);
    }

    setSaveActive(props.detail?.active);
  }, [props.detail]);

  useEffect(()=>{
    if (props.detail?.description != "") {
      handleDelError("description"); 
    }
  },[props.detail?.description])

  const handleCallSave = () => {
    props.onSave({ id: props.detail?.id });
    setPopup(false);
    setIsSave(false);
    setIsLoading(false);
  };

  // const handleGetHospitalOptions = useCallback(
  //   (params: { searchText: string }) => {
  //     return props.onEvent({
  //       message: "HandleGetHospitalOptions",
  //       params: { search: params.searchText },
  //     });
  //   },
  //   []
  // );

  const handleSelectOption = useCallback((item: any) => {
    const { organization } = item || {};
    props.onChange?.({
      clinic: {
        code: item?.division_code || null,
        name: item?.division_name || "",
        name_en: item?.division_name_en || item?.division_name,
      },
      hospital: {
        code: organization?.code || null,
        name: organization?.name || "",
        name_en: organization?.name_en || "",
      },
    });
  }, []);

  const handleChangeBanners = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      props.onChange?.({
        extra_field: {
          ...(props.detail?.extra_field || {}),
          banners_file: file,
          banners: file.name,
        },
      });
    }
  };

  const handleChangePhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0];
    if (file) {
      props.onChange?.({
        extra_field: {
          ...(props.detail?.extra_field || {}),
          image_file: file,
          image: URL.createObjectURL(file),
        },
      });
    }
  };

  const handleClickBrowse = () => {
    handleDelError("banners");
    bannersRef.current?.click?.();
  };

  const handleClickAddPhoto = () => {
    photoRef.current?.click?.();
  };

  const checkRequired = () => {
    let error = {};
    error = {
      ...error,
      ...(props.detail?.title === "" && { title: true }),
      ...(props.detail?.banners == null &&
        props.detail?.extra_field?.banners == null && { banners: true }),
      ...(props.detail?.description === "" && { description: true }),
    };

    props.onChange?.({ errorField: { ...error } });
    return !(
      props.detail?.title === "" ||
      props.detail?.description === "" ||
      props.detail?.title === "" ||
      (props.detail?.banners == null &&
        props.detail?.extra_field?.banners == null)
    );
  };

  const handleSave = () => {
    setSaveDetailId("");
    setIsSaveActive(false);
    setIsLoading(true);
    props.onSave({
      id: props.detail?.id,
      callback: () => {
        setIsLoading(false);
      },
    });
  };

  const handleActive = () => {
    setSaveActive(!saveActive);
    setIsSaveActive(true);
  };

  const handleDelete = () => {
    setIsSave(true);
    props.onChange?.({ delete: true });
  };

  const handleSaveCheck = () => {
    setIsLoading(true);
    setSaveDetailId(props.detail?.id);
    if (isSaveActive) {
      console.log(
        "handleSaveCheck called props.onChange?.({ active: saveActive }) "
      );
      props.onChange?.({ active: saveActive });
    } else {
      if (checkRequired()) {
        handleSave();
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleDelError = (type: string) => {
    const tmp: any = props.detail?.errorField || {};
    if (type in tmp) {
      tmp[type] = false;
    }
    props.onChange?.({ errorField: { ...tmp } });
  };

  const componentLabelError = (label: string) => {
    return (
      <div style={{ color: "rgba(0, 0, 0, 0.6)" }}>
        {label} <span style={{ color: "red", fontWeight: "normal" }}>* This field is required.</span>
      </div>
    );
  };



  return (
    <>
      {props.detail === null ? "No content selected" : null}
      {props.detail && (
        <Stack spacing={3} style={isLoading ? { pointerEvents: "none" } : {}}>
          <TextField
            {...(props.detail?.errorField?.title
              ? { error: true }
              : { required: true })}
            label={
              props.detail?.errorField?.title
                ? componentLabelError("Title")
                : "Title"
            }
            id="title-required"
            value={props.detail?.title || ""}
            onChange={(e: any) => {
              handleDelError("title");
              props.onChange?.({ title: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <TextField
            required
            id="description-required"
            label="Description"
            value={props.detail?.description || ""}
            onChange={(e: any) => {
              props.onChange?.({ description: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
          /> */}
          <PostFormByType
            type={"promotion"}
            detail={props.detail}
            onChange={props.onChange}
            componentLabelError={componentLabelError}
            errorField={props.detail?.errorField}
            handleDelError={handleDelError}
            // onEvent={props.onEvent}
          />
          <div className={classes.banners}>
            <TextField
              {...(props.detail?.errorField?.banners
                ? { error: true }
                : { required: true })}
              label={
                props.detail?.errorField?.banners
                  ? componentLabelError("Banners (15 x 250 px)")
                  : "Banners (15 x 250 px)"
              }
              id="banners-required"
              value={
                props.detail?.extra_field?.banners ||
                props.detail?.banners ||
                ""
              }
              InputProps={{
                disabled: true,
                style: {
                  ...(props.detail?.errorField?.banners && {
                    border: "1px solid red",
                  }),
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{ width: "65%" }}
            />
            <MuiButton
              variant="contained"
              color="primary"
              style={{ height: "45px" }}
              onClick={handleClickBrowse}
            >
              Browse
            </MuiButton>

            <input
              ref={bannersRef}
              type="file"
              accept="image/*"
              id="file"
              onChange={handleChangeBanners}
              hidden
            />
          </div>
          <div
            className="PostPreview-banners"
            // onClick={handleClickAddPhoto}
            style={{ cursor: "auto" }}
          >
            {props.detail?.extra_field?.banners_file ||
            props.detail?.banners ? (
              <img
                src={
                  props.detail?.extra_field?.banners_file
                    ? URL.createObjectURL(
                        props.detail?.extra_field?.banners_file
                      )
                    : props.detail?.banners || ""
                }
                alt="photo"
                style={{ height: "100%", objectFit: "contain" }}
              />
            ) : (
              <>
                <AddPhotoAlternateOutlinedIcon />
                <label>Add photos</label>
              </>
            )}
            <input
              ref={photoRef}
              type="file"
              accept="image/*"
              id="file"
              onChange={handleChangePhoto}
              hidden
            />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              required
              id="minprice-required"
              label="Price"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  maxWidth: "193px",
                  width: "193px",
                },
              }}
              type="number"
              placeholder="lowest price"
              value={props.detail?.minprice || ""}
              onChange={(e: any) => {
                props.onChange?.({ minprice: e.target.valueAsNumber });
              }}
            />

            <div style={{ margin: "0 25px" }}>-</div>

            <TextField
              required
              id="maxprice-required"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                style: {
                  maxWidth: "193px",
                  width: "193px",
                },
              }}
              type="number"
              placeholder="highest price"
              value={props.detail?.maxprice || ""}
              onChange={(e: any) => {
                props.onChange?.({ maxprice: e.target.valueAsNumber });
              }}
            />
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <DesktopDatePicker
              label="Available at"
              inputFormat="DD/MM/yyyy"
              value={
                props.detail?.available_start_date
                  ? moment(props.detail?.available_start_date)
                  : null
              }
              onChange={(value) => {
                props.onChange?.({
                  available_start_date: value?.toDate() || null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />

            <div style={{ margin: "0 25px" }}>-</div>

            <DesktopDatePicker
              inputFormat="DD/MM/yyyy"
              value={
                props.detail?.available_end_date
                  ? moment(props.detail?.available_end_date)
                  : null
              }
              onChange={(value) => {
                props.onChange?.({
                  available_end_date: value?.toDate() || null,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </div>
          <TextField
            id="tag-required"
            label="Tag"
            value={props.detail?.tags || ""}
            onChange={(e: any) => {
              props.onChange?.({ tags: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Autocomplete
            key={"segment-" + props.detail?.id}
            multiple
            id="segment-outlined"
            value={props.detail?.segment || []}
            options={
              (props.marketingSegmentList?.items?.map(
                (item: any) => item.result
              ) || []) as string[]
            }
            getOptionLabel={(option) => option}
            filterSelectedOptions
            onChange={(event, newValue) => {
              props.onChange?.({ segment: newValue });
            }}
            clearIcon={false}
            disableCloseOnSelect={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: "bplusClinicKey820" })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {/*
          
          // ถ้าจะใช้ PostSearchBox ให้ไปอ่าน params ที่ต้องใช้ก่อนมีการเปลี่ยนไปใช้ การเรียกแบบ canceller
          <PostSearchBox
            key={"hospital-" + props.detail?.id}
            label={intl.formatMessage({ id: "profile.hospital" })}
            initialValue={props.detail?.hospital?.name}
            onGetSearchOptions={handleGetHospitalOptions}
            onSelectOption={handleSelectOption}
            textField="name"
          /> */}

          {/* Select Division */}
          {/* <FormControl fullWidth key={"select-" + props.detail?.id}>
            <>
              <InputLabel shrink>Division</InputLabel>
              <Select
                readOnly
                label={intl.formatMessage({ id: "bplusClinicKey662" })}
                value={props.detail?.clinic?.code || ""}
                onChange={(e: any) => {
                  handleSelectOption(
                    props.divisionHasUser?.find(
                      (item) => item.division_code === e.target.value
                    ) || null
                  );
                }}
              >
                {(props.divisionHasUser || []).map(
                  (segment: any, index: number) => (
                    <MenuItem
                      key={"menu-item" + index}
                      value={segment.division_code}
                    >
                      {segment.division_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </>
          </FormControl> */}

          <div style={{ margin: "10px 0 -10px 10px" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Active"
              style={{ color: COLORS.red, fontWeight: "bold" }}
              checked={saveActive || false}
              onClick={handleActive}
            />
          </div>

          <div style={{ margin: "10px 0 -10px 20px" }}>
            <FormControl style={{ width: "30%" }}>
              <LoadingButton
                color="primary"
                loading={isLoading}
                loadingPosition="start"
                variant={isLoading ? "outlined" : "contained"}
                style={{ width: "100%", padding: "10px 0" }}
                onClick={handleSaveCheck}
              >
                Save
              </LoadingButton>
            </FormControl>

            <FormControl style={{ width: "30%", marginLeft: "10px" }}>
              <LoadingButton
                color="error"
                loading={isLoading}
                // loadingPosition="start"
                variant={isLoading ? "outlined" : "contained"}
                style={{ width: "100%", padding: "10px 0" }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                Delete
              </LoadingButton>
            </FormControl>
          </div>

          <Dialog
            PaperProps={{
              style: { borderRadius: 25 },
            }}
            open={popup}
            onClose={() => {
              setPopup(false);
            }}
          >
            <DialogContent>
              <div style={{ padding: "20px 40px" }}>
                <div style={{ ...styles.fontcenter }}>
                  <div>ต้องการลบ</div>
                  <div style={{ ...styles.fontbold, fontWeight: "bold" }}>
                    Content
                  </div>
                  <div>ต่อไปนี้หรือไม่ หากการลบสำเร็จ</div>
                </div>
                <div style={{ ...styles.fontcenter }}>
                  <div>คุณจะไม่สามารถกู้คืน</div>
                  <div style={{ ...styles.fontbold, fontWeight: "bold" }}>
                    Content
                  </div>
                  <div>นี้ได้อีก</div>
                </div>
                <div style={{ ...styles.fontcenter, marginTop: "40px" }}>
                  <FormControl style={{ width: "60%" }}>
                    <LoadingButton
                      loading={isLoading}
                      // loadingPosition="start"
                      variant={isLoading ? "outlined" : "contained"}
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        backgroundColor: "#0147A2",
                      }}
                      onClick={() => {
                        setPopup(false);
                      }}
                    >
                      Cancel
                    </LoadingButton>
                  </FormControl>

                  <FormControl style={{ width: "60%", marginLeft: "20px" }}>
                    <LoadingButton
                      color="error"
                      loading={isLoading}
                      // loadingPosition="start"
                      variant={isLoading ? "outlined" : "contained"}
                      style={{ width: "100%", padding: "5px 10px" }}
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>
                  </FormControl>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Stack>
      )}
    </>
  );
};

export default PromotionEX;
