import React, { CSSProperties, useEffect, useState, useMemo } from "react";
import { Card, Divider, Grid, Header, Icon, Image } from "semantic-ui-react";
// Mui
import { makeStyles, Button as MuiButton } from "@material-ui/core";

import moment from "moment";
import { injectIntl, WrappedComponentProps } from "react-intl";

// UI
import HospitalSearchBox from "../medication/HospitalSearchBox";
import MobSkeletonPHR from "./MobSkeletonPHR";
import TabMenu from "../history/TabMenu";

// Common
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SnackMessage from "../clinic/SnackMessage";

// Interface
import {
  BACKEND_DATE_FORMAT,
  BACKEND_TIME_FORMAT,
} from "../MobClinicInterface";

// Use styles
const COLORS = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
};

const useStyles = makeStyles(() => ({
  download: {
    color: "#767676 !important",
    "&.active": {
      color: `${COLORS.primary} !important`,
    },
  },
  header: {
    width: "92% !important",
    "& > .ui.segment.inverted.blue": {
      backgroundColor: `${COLORS.primary} !important`,
      padding: "1.2rem",
    },
  },
  button: {
    width: "100%",
    margin: "5px 0",
    fontSize: "16px",
    textTransform: "unset",
    backgroundColor: COLORS.primary,
    boxShadow: "none",
    "&:hover": {
      backgroundColor: COLORS.primary,
    },
    borderRadius: "8px",
    padding: "6.5px 0",
    "&.btn-outline": {
      border: `1px solid ${COLORS.primary}`,
      color: COLORS.primary,
      backgroundColor: "transparent",
      "&:hover": {
        border: `1px solid ${COLORS.primary}`,
        backgroundColor: "transparent",
      },
      marginTop: 0,
    },
  },
}));

const styles = {
  card: {
    width: "100%",
    fontSize: "15px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    lineHeight: "1.5em",
    borderRadius: "10px",
  } as CSSProperties,
  noMargin: {
    margin: "0px",
  } as CSSProperties,
  parentbox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  } as CSSProperties,
  title: {
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px 20px",
    color: COLORS.primary,
  } as CSSProperties,
  test_16_blue: {
    fontSize: "16px",
    fontWeight: "bold",
    color: COLORS.primary,
    margin: "0px",
  } as CSSProperties,
};

// Types
type MedicalCertificateProps = {
  onEvent: any;
  loadingStatus?: boolean;
  errorMessage: any;
  successMessage: any;
  medicalCertificateList?: any[];
  selectedHospital: any;
  hospitalList: any;
} & WrappedComponentProps;

const MedicalCertificateInitial: Omit<MedicalCertificateProps, "intl"> = {
  onEvent: () => null,
  loadingStatus: false,
  errorMessage: null,
  successMessage: null,
  medicalCertificateList: [],
  selectedHospital: null,
  hospitalList: null,
};

const MedicalCertificate: React.FC<MedicalCertificateProps> = (props) => {
  const [openModConfirm, setOpenModConfirm] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [preferred, setPreferred] = useState<string>("Preferred");
  const [medicalCertList, setMedicalCertList] = useState<any[]>([]);
  const [searchHospital, setSearchHospital] = useState<string>("");

  const classes = useStyles();

  useEffect(() => {
    if (props.selectedHospital != null) {
      props.onEvent({
        message: "handleGetMedicalCertificateList",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  }, [props.selectedHospital]);

  useEffect(() => {
    setMedicalCertList(props.medicalCertificateList || []);
  }, [props.medicalCertificateList]);

  useEffect(() => {
    const list = (props.medicalCertificateList || []).filter((item) =>
      item.hospital_name?.toLowerCase()?.includes(searchHospital.toLowerCase())
    );

    setMedicalCertList(list);
  }, [searchHospital]);

  const hospitalList = useMemo(() => {
    // const arrayFlat = (props.medicalCertificateList || []).map(
    //   (item) => {
    //     const name = item.hospital_name || props.selectedHospital?.name;
    //     return { name, code: name };
    //   }
    // );

    if (props.hospitalList !== undefined) {
      return Array.from(
        new Map(
          props.hospitalList.map((item: any) => [item["name"], item])
        ).values()
      );
    } else {
      const arrayFlat = (props.medicalCertificateList || []).map((item) => {
        const name = item.hospital_name || props.selectedHospital?.name;
        return { name, code: name };
      });

      return Array.from(
        new Map(arrayFlat.map((item: any) => [item["name"], item])).values()
      );
    }
  }, [
    props.medicalCertificateList,
    props.selectedHospital,
    props.hospitalList,
  ]);

  const handleOpenModConfirm = (item: any) => {
    setSelectedFile(item);
    setOpenModConfirm(true);
  };

  const handleCloseModConfirm = () => {
    setSelectedFile(null);
    setOpenModConfirm(false);
  };

  const handleDownloadFile = () => {
    const url =
      "apis/PRX/v3/med_cert_file/?hospital="+props.selectedHospital?.code+"&medcert_id=" + selectedFile.reportId;
    console.log("----- url", url);
    // console.log("globalThis.mobile", globalThis.mobile);
    console.log("globalThis", globalThis);
    console.log("----- typeof globalThis.mobile", typeof globalThis.mobile);
    const en = selectedFile.en.replaceAll("-","").toLowerCase();
    const filename = `medical_cert_${en}.pdf`
    props.onEvent({
      message: "handleMobileDownloadMedCert",
      params: { url: url , filename: filename},
    });

    handleCloseModConfirm();
  };

  const closeSnackMessage = () => {
    props.onEvent({ message: "closeSnackMessage" });
  };

  const handleChangePreferred = (preferred: string) => {
    setPreferred(preferred);
    if (preferred === "All") {
      props.onEvent({
        message: "handleGetMedicalCertificateList",
        params: { hospital: "all" },
      });
    } else {
      props.onEvent({
        message: "handleGetMedicalCertificateList",
        params: { hospital: props.selectedHospital?.code },
      });
    }
  };

  const handleSearch = (value: string) => {
    setSearchHospital(value);
  };

  return (
    <div style={{ padding: "15px" }}>
      {/* <Dimmer
        active={props.loadingStatus}
        style={{ marginTop: "50px" }}
        inverted
      >
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer> */}

      <SnackMessage
        onEvent={props.onEvent}
        onClose={closeSnackMessage}
        error={props.errorMessage}
        success={props.successMessage}
      />
      <div style={styles.parentbox}>
        <div style={{ marginBottom: "20px" }}>
          <div style={styles.title}>{"Medical Certification"}</div>
          <Divider
            style={{
              background: "red",
              marginTop: "5px",
              marginBottom: "5px",
            }}
            variant="middle"
          />
        </div>

        <TabMenu
          tab={preferred}
          leftTitle="Preferred"
          rightTitle="All"
          // config
          paddingStyle="0"
          marginStyle="0 0 15px"
          rightDisabled={props.loadingStatus}
          leftDisabled={props.loadingStatus}
          // callback
          onChange={handleChangePreferred}
        />
      </div>

      {props.loadingStatus ? (
        <>
          <MobSkeletonPHR margin="10px 0" />
        </>
      ) : (
        <>
          {preferred === "All" && (
            <HospitalSearchBox
              hospitalList={hospitalList}
              margin={0}
              width="88%"
              onSearchChange={handleSearch}
            />
          )}

          {!medicalCertList?.length && (
            <Header as="h3" color="grey" style={{ textAlign: "center" }}>
              {props.intl.formatMessage({ id: "common.no_data_medcert" })}
            </Header>
          )}

          {medicalCertList.map((item: any, index: number) => {
            return (
              <Card key={index} style={styles.card}>
                <Card.Content>
                  <Grid
                    verticalAlign="middle"
                    style={{
                      display: "grid",
                      gridTemplateColumns: "76.5px 1fr",
                    }}
                  >
                    <Grid.Column
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Image
                        avatar
                        className="left floated small ui image rounded"
                        src={
                          item?.doctor_image_url ||
                          "/images/doctor_placeholder.png"
                        }
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover",
                          margin: 0,
                        }}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = "/images/doctor_placeholder.png";
                        }}
                      />
                    </Grid.Column>
                    <Grid.Column style={{ width: "100%", paddingLeft: 0 }}>
                      <div style={{ ...styles.test_16_blue }}>
                        {item?.doctor_fullname || item?.doctorName}
                      </div>
                      <Grid>
                        <Grid.Column width={12}>
                          <Header
                            as="h4"
                            color="grey"
                            style={{ margin: "5px 0px 0px" }}
                          >
                            {item?.documentTime
                              ? moment(
                                  item?.documentTime,
                                  `${BACKEND_DATE_FORMAT} ${BACKEND_TIME_FORMAT}`
                                ).format("DD-MM-YYYY")
                              : "-"}
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={3}
                          style={{
                            paddingRight: 0,
                            justifyContent: "flex-end",
                            display: "flex",
                          }}
                        >
                          <button
                            disabled={!item?.reportId}
                            style={{
                              border: "none",
                              background: "none",
                              paddingRight: 0,
                            }}
                            onClick={() => {
                              handleOpenModConfirm(item);
                            }}
                          >
                            <Icon.Group size="big">
                              <Icon
                                name="file outline"
                                className={`${classes.download}${
                                  item?.reportId ? " active" : ""
                                }`}
                              />
                              <Icon
                                corner="bottom right"
                                name="download"
                                className={`${classes.download}${
                                  item?.reportId ? " active" : ""
                                }`}
                              />
                            </Icon.Group>
                          </button>
                        </Grid.Column>
                      </Grid>
                      <Header as="h4" color="grey" style={styles.noMargin}>
                        {item.hospital_name ||
                          props.selectedHospital?.name ||
                          ""}
                      </Header>
                      <Divider style={{ margin: "7px 0px" }} />
                      <Header as="h4" color="grey" style={styles.noMargin}>
                        {item.templateName}
                      </Header>
                    </Grid.Column>
                  </Grid>
                </Card.Content>
              </Card>
            );
          })}

          <ModConfirm
            openModal={openModConfirm}
            titleName={props.intl.formatMessage({
              id: "download.download",
            })}
            content={props.intl.formatMessage({
              id: "download.download_pdf_info_medcert",
            })}
            approveButton={
              <MuiButton
                className={`${classes.button} btn-outline`}
                variant="contained"
                onClick={handleDownloadFile}
              >
                {props.intl.formatMessage({
                  id: "download.download",
                })}
              </MuiButton>
            }
            denyButtonText=""
            buttonClassName={`centered seven wide`}
            className={classes.header}
            // callback
            onDeny={handleCloseModConfirm}
            onCloseWithDimmerClick={handleCloseModConfirm}
          />
        </>
      )}
    </div>
  );
};

MedicalCertificate.defaultProps = MedicalCertificateInitial;

export default React.memo(injectIntl(MedicalCertificate));
