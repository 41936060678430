import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
// CSS
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useIntl } from "react-intl";
// Selected
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
// Input
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import LoadingButton from "@mui/lab/LoadingButton";
// DatePicker
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File // import ที่เดียว

import moment from "moment";

// Interface
import { Event, State } from "../ManageInterface";
import { PostData, ContentFileType } from "./PostExInterface";

// UI
import PostTab from "./PostTab";
import PostList from "./PostList";
import PostChart from "./PostChart";
import PostSearchBox from "./PostSearchBox";
import PostSegment from "./PostSegment";
import MobContentFull from "../feed/MobContentFull";
import MobContentDetail from "../feed/MobContentDetail";
import PostFormByType from "./PostFormByType";

type PostExProps = {
  // callback
  onEvent: (e: Event) => any;
} & Pick<
  State,
  | "marketingPostList"
  | "marketingPostIndex"
  | "clinicalSegment"
  | "loadingMainPost"
  | "marketingSegmentList"
  | "marketingPostId"
  | "divisionHasUser"
  | "marketingPostChart"
  | "selectedDivisionId"
  | "userProfile"
  | "doctorOptions"
  | "doctorOptionsLoading"
>;

type PostEditorProps = {
  // data
  detail: PostData | null;
  // callback
  onChange?: (data: Partial<PostData>) => any;
  onSave: (params: {
    id: any;
    callback?: (params: { content_files: ContentFileType[] }) => any;
  }) => any;
} & Pick<
  PostExProps,
  "onEvent" | "clinicalSegment" | "marketingSegmentList" | "divisionHasUser" | "doctorOptions" | "doctorOptionsLoading"
>;

type FormByTypeProps = {
  type: "article" | "url" | "picture" | "video";
} & Pick<PostEditorProps, "detail" | "onChange" | "onEvent">;

type PostPreviewProps = {
  detail: any;
};

type FormByTypeRef = {
  setContentFile: (data: ContentFileType[]) => void;
};

const COLORS = {
  primary: "var(--text-light-blue)",
  light_blue: "#0072BC",
  dark_grey: "#797878",
  red: "#DA0000",
};

const styles = {
  fontbold: {
    fontWeigt: "bold",
    margin: "0px 10px",
  },
  fontcenter: {
    display: "flex",
    justifyContent: "center",
  },
};

const ICONS = {
  edit: "/images/expansion/bx_bx-edit.png",
  carbon_analytics: "/images/expansion/carbon_analytics.png",
  user_activity: "/images/expansion/carbon_user-activity.png",
};

const TYPE_LIST = [
  { id: "article", name: "Article" },
  { id: "url", name: "Url" },
  { id: "picture", name: "Picture" },
  { id: "video", name: "Video" },
];

const useStyles = makeStyles((theme) => ({
  banners: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: "25px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
    "& .MuiButton-root.MuiButton-contained": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
}));

const PostEx: React.FunctionComponent<PostExProps> = (props) => {
  const [title, setTitle] = useState<string>("");
  const [filterSelected, setFilterSelected] = useState<string>("all");
  const [firstSelect, setFirstSelect] = useState<boolean>(false);
  const [postList, setPostList] = useState<PostData[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    props.onEvent({ message: "GetListMarketingPost", params: {} });
  }, []);

  const displayPostList = useMemo(() => {
    return (props.marketingPostList || []).filter((item) => item.id !== null);
  }, [props.marketingPostList]);

  useEffect(() => {
    const filter = displayPostList
      ?.filter((all: any) => all.divisionId === props.selectedDivisionId)
      ?.filter((item: any) => {
        if (filterSelected === "active") {
          return item?.active === true;
        } else if (filterSelected === "inactive") {
          return item?.active === false;
        } else {
          return item?.active !== null;
        }
      })
      ?.filter((item: any) =>
        item.title?.toLowerCase()?.includes(title?.toLowerCase())
      );
    setPostList(filter);

    if (
      (props.marketingPostIndex === 0 &&
        filter[0]?.id !== displayPostList[0]?.id &&
        props.marketingPostId) ||
      firstSelect
    ) {
      setFirstSelect(false);
      if (filter[0] !== undefined) {
        handleClickItem(filter[0], 0);
      } else {
        handleAddPost();
      }
    }
  }, [
    props.marketingPostList,
    title,
    filterSelected,
    props.selectedDivisionId,
  ]);

  const activeIndex = useMemo(() => {
    const index = props.marketingPostIndex || 0;

    return props.marketingPostList?.[index] !== null;
  }, [props.marketingPostList, props.marketingPostIndex]);

  const handleClickItem = (data: PostData, index: number) => {
    props.onEvent({
      message: "HandleSetMarketingPostIndex",
      params: {
        index: displayPostList.findIndex((item) => item.id === data.id),
      },
    });
  };

  const handleChange = (data: Record<string, any>) => {
    props.onEvent({
      message: "HandleChangeMarketingPost",
      params: { data },
    });
  };

  const handleAddPost = () => {
    props.onEvent({ message: "HandleAddNewMarketingPost", params: {} });
  };

  const handleSavePost = (params: {
    id: any;
    callback?: (data: { content_files: ContentFileType[] }) => any;
  }) => {
    props.onEvent({
      message: "SaveMarketingPost",
      params,
    });
  };

  const handleSearchTitle = (search: string) => {
    setTitle(search);
  };

  const handleFilterActive = (select: string) => {
    setFilterSelected(select);
    setFirstSelect(true);
  };

  const handleGetChart = () => {
    if (activeTab === 2) {
      props.onEvent({
        message: "HandleGetPostChart",
        params: {
          id:
            props.marketingPostList?.[props.marketingPostIndex || 0]?.id ||
            null,
        },
      });
    }
  };

  const handleSetActiveTab = (item: any, index: number) => {
    setActiveTab(index);
  };

  console.log(props);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "320px 1fr 375px",
          height: "calc(100vh - 47px)",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            gridColumn: 1,
            paddingTop: "10px",
            borderRight: "1px solid rgb(238, 238, 238)",
          }}
        >
          <PostList
            onEvent={props.onEvent}
            list={postList}
            activeId={props.marketingPostId}
            disabled={!activeIndex}
            titleKey="title"
            dateKey="posting_date"
            onClickItem={handleClickItem}
            onAdd={handleAddPost}
            onSearch={handleSearchTitle}
            onFilterActive={handleFilterActive}
            filterActive={filterSelected}
            loadingStatus={props.loadingMainPost}
            showFilter={true}
            searchAddPlaceholder="Title"
            searchPlaceholder="Clinic"
          />
        </div>
        <div className="PostEditor">
          <PostTab
            initialActive={0}
            onClickTab={handleSetActiveTab}
            disableMenu={false}
            panes={[
              {
                key: "editor",
                image: ICONS.edit,
                render: () => (
                  <PostEditor
                    onEvent={props.onEvent}
                    onChange={handleChange}
                    detail={
                      props.marketingPostList?.[
                        props.marketingPostIndex || 0
                      ] || null
                    }
                    clinicalSegment={props.clinicalSegment}
                    marketingSegmentList={props.marketingSegmentList}
                    divisionHasUser={props.divisionHasUser}
                    onSave={handleSavePost}
                    doctorOptions={props.doctorOptions}
                    doctorOptionsLoading={props.doctorOptionsLoading}
                  />
                ),
              },
              {
                key: "segment",
                image: ICONS.user_activity,
                render: () => (
                  <PostSegment
                    onEvent={props.onEvent}
                    id={
                      props.marketingPostList?.[props.marketingPostIndex || 0]
                        ?.id
                    }
                    marketingSegmentList={props.marketingSegmentList}
                    manageSegmentList={
                      props.marketingPostList?.[props.marketingPostIndex || 0]
                        ?.segment || []
                    }
                    onChange={handleChange}
                    onSave={handleSavePost}
                  />
                ),
              },
              {
                key: "chart",
                image: ICONS.carbon_analytics,
                render: () => (
                  <PostChart
                    onEvent={props.onEvent}
                    data={props.marketingPostChart}
                    onGetChart={handleGetChart}
                    id={
                      props.marketingPostList?.[props.marketingPostIndex || 0]
                        ?.id || null
                    }
                  />
                ),
              },
            ]}
          />
        </div>

        <div style={{ gridColumn: 3 }}>
          <PostPreview
            detail={
              props.marketingPostList?.[props.marketingPostIndex || 0] || null
            }
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

/* TODO --------------------- PostEditor --------------------- */
const PostEditor: React.FunctionComponent<PostEditorProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorField, setErrorField] = useState<any>({});
  const [popup, setPopup] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [isSaveActive, setIsSaveActive] = useState<boolean>(false);
  const [saveActive, setSaveActive] = useState<boolean | undefined>(false);
  const [saveDetailId, setSaveDetailId] = useState<string | null | undefined>(
    ""
  );

  const intl = useIntl();
  const formByTypeRef = useRef<FormByTypeRef>();

  // const handleGetHospitalOptions = useCallback(
  //   (params: { searchText: string }) => {
  //     return props.onEvent({
  //       message: "HandleGetHospitalOptions",
  //       params: { search: params.searchText },
  //     });
  //   },
  //   []
  // );
  useEffect(() => {
    if (isSave) {
      handleCallSave();
    }

    if (isSaveActive && props.detail?.id === saveDetailId) {
      handleSave();
    } else {
      setSaveDetailId("");
      setIsSaveActive(false);
    }

    setSaveActive(props.detail?.active);
  }, [props.detail]);

  useEffect(()=>{
    if (props.detail?.description != "") {
      handleDelError("description"); 
    }
  },[props.detail?.description])

  const handleCallSave = () => {
    props.onSave({ id: props.detail?.id });
    setPopup(false);
    setIsSave(false);
    setIsLoading(false);
  };

  // const handleGetHospitalOptions = useCallback(
  //   (params: { searchText: string }) => {
  //     return props.onEvent({
  //       message: "HandleGetHospitalOptions",
  //       params: { search: params.searchText },
  //     });
  //   },
  //   []
  // );

  // const handleGetClinicOptions = useCallback(
  //   (params: { searchText: string }) => {
  //     return props.onEvent({
  //       message: "HandleGetClinicOptions",
  //       params: { search: params.searchText },
  //     });
  //   },
  //   []
  // );

  const handleGetDoctorOptions = useCallback(
    (params: { searchText: string }) => {
      return props.onEvent({
        message: "HandleGetDoctorOptions",
        params: { search: params.searchText },
      });
    },
    []
  );

  const handleSelectClinic = useCallback((item: any) => {
    const { organization } = item || {};
    props.onChange?.({
      clinic: {
        code: item?.division_code || null,
        name: item?.division_name || "",
        name_en: item?.division_name_en || item?.division_name,
      },
      hospital: {
        code: organization?.code || null,
        name: organization?.name || "",
        name_en: organization?.name_en || "",
      },
    });
  }, []);

  const handleSelectAuthor = useCallback((item: any) => {
    props.onChange?.({
      doctor: {
        id: item?.id || null,
        full_name: item?.full_name || "",
        code: item?.code || null,
      },
    });
  }, []);

  const handleSelectHospital = useCallback((item: any) => {
    props.onChange?.({
      hospital: {
        code: item?.code || null,
        name: item?.name || "",
        name_en: item?.name_en || "",
      },
    });
  }, []);

  const handleSave = () => {
    setSaveDetailId("");
    setIsSaveActive(false);
    setIsLoading(true);
    props.onSave({
      id: props.detail?.id,
      callback: ({ content_files }) => {
        setIsLoading(false);
        setTimeout(() => {
          formByTypeRef.current?.setContentFile(content_files);
        }, 10);
      },
    });
  };

  const handleActive = () => {
    setSaveActive(!saveActive);
    setIsSaveActive(true);
  };

  const handleDelete = () => {
    setIsSave(true);
    props.onChange?.({ delete: true });
  };

  const checkRequired = (type: string) => {
    let error = {};
    if (props.detail?.title === "") {
      error = { ...error, title: true };
    }

    if (props.detail?.type === "article") {
      error = {
        ...error,
        ...(props.detail.banners == null &&
          props.detail?.extra_field?.banners == null && { banners: true }),
        ...(props.detail.description === "" && { description: true }),
      };

      props.onChange?.({ errorField: { ...error } });

      return !(
        props.detail?.description === "" ||
        props.detail?.title === "" ||
        (props.detail.banners == null &&
          props.detail?.extra_field?.banners == null)
      );
    } else if (props.detail?.type === "picture") {
      error = {
        ...error,
        ...(props.detail.short_description === "" && {
          short_description: true,
        }),
      };

      props.onChange?.({ errorField: { ...error } });

      return !(
        props.detail.short_description === "" || props.detail?.title === ""
      );
    } else if (props.detail?.type === "url") {
      error = {
        ...error,
        ...((props.detail.url == null || props.detail.url === "") && {
          url: true,
        }),
      };

      props.onChange?.({ errorField: { ...error } });

      return !(
        props.detail.url === "" ||
        props.detail?.title === "" ||
        props.detail.url == null
      );
    } else if (props.detail?.type === "video") {
      error = {
        ...error,
        ...(props.detail.short_description === "" && {
          short_description: true,
        }),
        ...((props.detail.youtube_url == null ||
          props.detail.youtube_url === "") && { youtube_url: true }),
      };

      props.onChange?.({ errorField: { ...error } });

      return !(
        props.detail.short_description === "" ||
        props.detail.youtube_url == null ||
        props.detail.youtube_url === "" ||
        props.detail?.title === ""
      );
    } else {
      return false;
    }
  };

  const handleSaveCheck = () => {
    setIsLoading(true);
    setSaveDetailId(props.detail?.id);
    if (isSaveActive) {

      props.onChange?.({ active: saveActive });
    } else {
      if (checkRequired(props.detail?.type || "")) {
        handleSave();
      } else {
        setIsLoading(false);
      }
    }
  };

  const handleDelError = (type: string) => {
    const tmp: any = props.detail?.errorField || {};
    if (type in tmp) {
      tmp[type] = false;
    }
    props.onChange?.({ errorField: { ...tmp } });
  };

  const componentLabelError = (label: string) => {
    return (
      <div style={{ color: "rgba(0, 0, 0, 0.6)" }}>
        {label} <span style={{ color: "red" }}>* This field is required.</span>
      </div>
    );
  };

  return (
    <>
      {props.detail === null ? "No content selected" : null}
      {props.detail && (
        <Stack spacing={3} style={isLoading ? { pointerEvents: "none" } : {}}>
          <TextField
            id="title-required"
            {...(props.detail?.errorField?.title
              ? { error: true }
              : { required: true })}
            label={
              props.detail?.errorField?.title
                ? componentLabelError("Title")
                : "Title"
            }
            value={props.detail?.title}
            onChange={(e: any) => {
              handleDelError("title");
              props.onChange?.({ title: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl fullWidth>
            <>
              <InputLabel shrink>Type</InputLabel>
              <Select
                label={intl.formatMessage({ id: "bplusClinicKey662" })}
                value={props.detail?.type || "article"}
                onChange={(e: any) => {
                  setErrorField({});
                  props.onChange?.({ type: e.target.value });
                }}
              >
                {TYPE_LIST.map((segment: any, index: number) => (
                  <MenuItem key={"menu-item" + index} value={segment.id}>
                    {segment.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          </FormControl>

          <PostFormByType
            ref={formByTypeRef}
            type={props.detail?.type || "article"}
            detail={props.detail}
            onChange={props.onChange}
            onEvent={props.onEvent}
            componentLabelError={componentLabelError}
            errorField={props.detail?.errorField}
            handleDelError={handleDelError}
          />

          <TextField
            id="tag-required"
            label="Tag"
            value={props.detail?.tags || ""}
            onChange={(e: any) => {
              props.onChange?.({ tags: e.target.value });
            }}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Autocomplete
            key={"segment-" + props.detail?.id}
            multiple
            id="segment-outlined"
            value={props.detail?.segment || []}
            options={
              (props.marketingSegmentList?.items?.map(
                (item: any) => item.result
              ) || []) as string[]
            }
            getOptionLabel={(option) => option}
            filterSelectedOptions
            onChange={(event, newValue) => {
              props.onChange?.({ segment: newValue });
            }}
            clearIcon={false}
            disableCloseOnSelect={true}
            renderInput={(params) => (
              <TextField
                {...params}
                label={intl.formatMessage({ id: "bplusClinicKey820" })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          {/* <PostSearchBox
            key={`hospital-${props.detail?.id || ""}`}
            label={intl.formatMessage({ id: "profile.hospital" })}
            initialValue={props.detail?.hospital?.name}
            onGetSearchOptions={handleGetHospitalOptions}
            onSelectOption={handleSelectHospital}
            textField="name"
          />

          <PostSearchBox
            label={intl.formatMessage({ id: "bplusClinicKey114" })}
            key={`clinic-${props.detail?.id}`}
            initialValue={props.detail?.clinic?.name}
            onGetSearchOptions={handleGetClinicOptions}
            onSelectOption={handleSelectClinic}
            textField="name"
          /> */}

          {/* Select Division */}
          {/* <FormControl fullWidth key={"select-" + props.detail?.id}>
            <>
              <InputLabel shrink>Division</InputLabel>
              <Select
                readOnly
                label={intl.formatMessage({ id: "bplusClinicKey662" })}
                value={props.detail?.clinic?.code || ""}
                onChange={(e: any) => {
                  handleSelectClinic(
                    props.divisionHasUser?.find(
                      (item) => item.division_code === e.target.value
                    ) || null
                  );
                }}
              >
                {(props.divisionHasUser || []).map(
                  (segment: any, index: number) => (
                    <MenuItem
                      key={"menu-item" + index}
                      value={segment.division_code}
                    >
                      {segment.division_name}
                    </MenuItem>
                  )
                )}
              </Select>
            </>
          </FormControl> */}

          <PostSearchBox
            label="Author (Physician)"
            key={`author-${props.detail?.id}`}
            initialValue={props.detail?.doctor?.full_name}
            onGetSearchOptions={handleGetDoctorOptions}
            onSelectOption={handleSelectAuthor}
            textField="full_name"
            options={props.doctorOptions}
            loadingOptions={props.doctorOptionsLoading}
          />

          <DesktopDatePicker
            label="Posting date"
            inputFormat="DD/MM/yyyy"
            value={
              props.detail?.posting_date
                ? moment(props.detail?.posting_date)
                : null
            }
            onChange={(value) => {
              props.onChange?.({
                posting_date: value?.toDate() || null,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />

          <div style={{ margin: "10px 0 -10px 20px" }}>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Appointment (Suggest)"
              style={{ marginRight: "25px", fontWeight: "bold" }}
              checked={props.detail?.is_appointment || false}
              onClick={(e: any) => {
                props.onChange?.({
                  is_appointment: !props.detail?.is_appointment,
                });
              }}
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Active"
              style={{ color: COLORS.red, fontWeight: "bold" }}
              checked={saveActive || false}
              onClick={handleActive}
            />
          </div>

          <div style={{ margin: "10px 0 -10px 20px" }}>
            <FormControl style={{ width: "30%" }}>
              <LoadingButton
                color="primary"
                loading={isLoading}
                // loadingPosition="start"
                variant={isLoading ? "outlined" : "contained"}
                style={{ width: "100%", padding: "10px 0" }}
                onClick={handleSaveCheck}
              >
                Save
              </LoadingButton>
            </FormControl>

            <FormControl style={{ width: "30%", marginLeft: "10px" }}>
              <LoadingButton
                color="error"
                loading={isLoading}
                // loadingPosition="start"
                variant={isLoading ? "outlined" : "contained"}
                style={{ width: "100%", padding: "10px 0", display: "content" }}
                onClick={() => {
                  setPopup(true);
                }}
              >
                Delete
              </LoadingButton>
            </FormControl>
          </div>
          <Dialog
            PaperProps={{
              style: { borderRadius: 25 },
            }}
            open={popup}
            onClose={() => {
              setPopup(false);
            }}
          >
            <DialogContent>
              <div style={{ padding: "20px 40px" }}>
                <div style={{ ...styles.fontcenter }}>
                  <div>ต้องการลบ</div>
                  <div style={{ ...styles.fontbold, fontWeight: "bold" }}>
                    Content
                  </div>
                  <div>ต่อไปนี้หรือไม่ หากการลบสำเร็จ</div>
                </div>
                <div style={{ ...styles.fontcenter }}>
                  <div>คุณจะไม่สามารถกู้คืน</div>
                  <div style={{ ...styles.fontbold, fontWeight: "bold" }}>
                    Content
                  </div>
                  <div>นี้ได้อีก</div>
                </div>
                <div style={{ ...styles.fontcenter, marginTop: "40px" }}>
                  <FormControl style={{ width: "60%" }}>
                    <LoadingButton
                      loading={isLoading}
                      variant={isLoading ? "outlined" : "contained"}
                      style={{
                        width: "100%",
                        padding: "5px 10px",
                        backgroundColor: "#0147A2",
                      }}
                      onClick={() => {
                        setPopup(false);
                      }}
                    >
                      Cancel
                    </LoadingButton>
                  </FormControl>

                  <FormControl style={{ width: "60%", marginLeft: "20px" }}>
                    <LoadingButton
                      color="error"
                      loading={isLoading}
                      variant={isLoading ? "outlined" : "contained"}
                      style={{ width: "100%", padding: "5px 10px" }}
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>
                  </FormControl>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </Stack>
      )}
    </>
  );
};

/* TODO -------------------- PostPreview; -------------------- */
export const PostPreview: React.FunctionComponent<PostPreviewProps> = (
  props
) => {
  const detailData = useMemo(() => {
    return {
      ...(props.detail || {}),
      banners: props.detail?.extra_field?.banners_file
        ? URL.createObjectURL(props.detail?.extra_field?.banners_file)
        : props.detail?.banners,
      image: props.detail?.extra_field?.image || props.detail?.image,
      images:
        props.detail?.extra_field?.image_files
          ?.filter((item: any) => item.active)
          ?.map((item: any) => item.image) || props.detail?.images,
    };
  }, [props.detail]);

  return (
    <div className="PostPreview">
      <div className="PostHeader-mobilePreview">Mobile Preview</div>
      <div className="PostBox-main">
        <div className="PostBox-mobilePreview">
          {props.detail && (
            <div className="PostContent-mobilePreview">
              <MobContentFull
                marketingContent={detailData}
                onClose={() => null}
                isPreview={true}
              />
            </div>
          )}

          {props.detail && (
            <div className="PostContent-mobilePreview short">
              <MobContentDetail item={detailData} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PostEx);
