import { WasmHandler } from "react-lib/frameworks/WasmController";

import moment from "moment";
import axios from "axios";
import CONFIG from "config/config";

// APIS
import DoctorList from "issara-sdk/apis/DoctorList_core";
import V3SearchProgramSpecialtyListView from "issara-sdk/apis/V3SearchProgramSpecialtyListView_apps_PRX";

// Interface
import { GetContentGenPreview } from "../manage/ClinicContentInterface";
import { State as MainState } from "../ManageInterface";
import { uploadFile, deleteFile } from "./PromotionExInterface";

import firebaseConfig from "../../_manager/firebase-config";
import { addDoc, collection, doc, getDocs, orderBy, query, setDoc, updateDoc, where } from "firebase/firestore";


export type PostData = {
  type: "article" | "url" | "picture" | "video";
  doctor: {
    id: number | null;
    full_name: string;
    code: string | null;
  };
  is_appointment: boolean;
  url: string | null;
  short_description: string;
  images: string[];
  youtube_url: string | null;
  posting_date: any;
  editor: string | null;
  edit_date: any;
  extra_field?: {
    banners_file?: File;
    banners?: string | null;
    image_files?: ImageFilesType[];
    images?: string[];
    content_files?: ContentFileType[];
  };
  errorField?: {
    description? : boolean,
    title? : boolean,
    banners? : boolean,
    short_description? : boolean,
    url? : boolean,
    youtube_url? : boolean
  }
} & CommonData;

export type CommonData = {
  id?: string | null;
  engagement_id?: string | null;
  content_id?: string | null;
  created?: any;
  title: string;
  active: boolean;
  delete: boolean;
  banners: string | null;
  description: string;
  tags: string;
  segment: string[];
  hospital: {
    code: string | null;
    name: string;
    name_en: string;
  };
  clinic: {
    code: string | null;
    name: string;
    name_en: string;
  };
  divisionId: number | null;
  divisionCode: string | null;
  manage_segment: { result: string; patient: number[] }[];
  organization: number | null;
  fake_follow?: boolean; // ใช้เพื่อการแสดงผล
  visible?: boolean; //เงื่อนไขแสดง Card
} & EngagementData;

export type SaveData = {
  patient: number;
  date: any;
  gender: "MALE" | "FEMALE";
  age: number;
};

export type SaveDataField =
  | "likes"
  | "clicks"
  | "saved"
  | "shares"
  | "follows"
  | "reach";

export type EngagementData = {
  [key in SaveDataField]?: SaveData[];
};

type ImageFilesType = { image: string; file?: File | null; active: boolean };

export type ContentFileType = {
  index: number;
  name: string;
  file?: File;
  status: string;
  src: string;
};

type DivisionIndexData = {
  id: number;
  code?: string;
  name?: string;
  name_en?: string;
};

export type State = Partial<{
  marketingPostList: PostData[];
  marketingPostIndex: number;
  marketingPostId: string | null;
  marketingPostChart: EngagementData;
  loadingMainPost: boolean;
  divisionIndexList: DivisionIndexData[];
  // organizationIndexList: DivisionIndexData[];
  organizationIndexList: DivisionIndexData[];
  userProfile: any;
  doctorOptionsController?: any;
  doctorOptions?: any;
  doctorOptionsLoading?: boolean;
}>;

type Picked = Pick<
  MainState,
  | "selectedDivisionId"
  | "errorMessage"
  | "successMessage"
  | "divisionHasUser"
  | "selectedDivision"
  | "loadingStatus"
>;

export const StateInitial: Required<State> = {
  marketingPostList: [],
  marketingPostIndex: 0,
  marketingPostId: null,
  loadingMainPost: false,
  divisionIndexList: [],
  // organizationIndexList: [],
  marketingPostChart: {} as any,
  userProfile: null,
  organizationIndexList: [],
  doctorOptionsController: null,
  doctorOptions: [],
  doctorOptionsLoading: false
};

const POST_DIVISION = "PostDivision";
const GS_BUCKET_NAME = `gs://${CONFIG.PROMOTION_STORAGE_NAME}`;
const DIVISION_INDEX = "divisionIndex";
const ORGANIZATION_INDEX = "organizationIndex";
const POST_ENGAGEMENT = "PostEngagement";

export type Event =
  // GET
  | { message: "GetListMarketingPost"; params: {} }
  | { message: "HandleGetDoctorOptions"; params: { search: string } }
  | { message: "HandleGetClinicOptions"; params: { search: string } }
  | { message: "HandleGetPostChart"; params: { id: string | null } }
  // SAVE
  | {
    message: "SaveMarketingPost";
    params: {
      id: string | null;
      callback?: (data: { content_files: ContentFileType[] }) => any;
    };
  }
  // SET
  | { message: "HandleSetMarketingPostIndex"; params: { index: number } }
  | {
    message: "HandleChangeMarketingPost";
    params: { data: Partial<PostData> };
  }
  | {
    message: "HandleAddNewMarketingPost";
    params: {};
  }
  | {
    message: "HandleSubmitUrl";
    params: {
      link: string | null;
      title?: string | null;
      callback?: () => any;
    };
  };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event, Data>;

/* ------------------------- GET ------------------------ */
export const GetMarketing: Handler = async (
  controller,
  params: { collection: string }
): Promise<[any[] | null, any]> => {
  try {

    const marketRef = collection(controller.db, params.collection);

    const q = query(marketRef, orderBy("created", "desc"));
    const querySnapshot = await getDocs(q);

    // const result = await controller.db
    //   .collection(params.collection)
    //   .orderBy("created", "desc")
    //   .get();
  
    const list = querySnapshot?.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    })).filter((item)=> !item.delete) as any[];

    return [list, null];
  } catch (e) {
    console.log("Error getting clinic content from firebase");
    return [null, (e as Error).toString()];
  }
};

const GetMarketingPost: Handler = async (controller, params) => {
  const [res, error] = await GetMarketing(controller, {
    collection: POST_DIVISION,
  });

  return [
    res?.map((item: PostData) => ({
      ...item,
      posting_date: item.posting_date ? item.posting_date.toDate() : null,
      edit_date: item.edit_date ? item.edit_date.toDate() : (item.created ?  item.created.toDate() : null),
    })),
    error,
  ];
};

export const GetListMarketingPost: Handler = async (controller, params) => {
  controller.setState({ loadingMainPost: true });

  // GetClinicalSegment(controller as any, {});
  controller.handleEvent({ message: "handleGetMarketingSegmentList" } as any);

  const [res, error] = await GetMarketingPost(controller, {});

  if (res) {
    const list = res?.filter((item: any ) => item.division_id === params.division && !item.delete)
    controller.setState({
      marketingPostList: list,
      marketingPostIndex: 0,
      marketingPostId: list[0]?.id || null,
    });
  } else {
    console.log(error);
  }

  controller.setState({ loadingMainPost: false });
};

export const HandleGetDoctorOptions: Handler = async (controller, params) => {

  let state = controller.getState();

  controller.setState({
    doctorOptionsLoading: true,
  });

  state = controller.getState();
  if (state.doctorOptionsController) {
    console.log("Debug ~ HandleGetDoctorOptions abort")
    state.doctorOptionsController.abort();
  }

  const abortController = new AbortController()
  controller.setState({
    doctorOptionsController: abortController,
  });

  console.log("Debug ~ detectLanguage ", detectLanguage(params.search || ""))

  const [res, error] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: {
      search: params.search,
      limit: 10,
    },
    extra: {
      signal: abortController.signal,
      headers: {
        'Accept-Language': detectLanguage(params.search || "")
      },
    },
  });
  console.log("Debug ~ res:", res)
  console.log("Debug ~ error:", error)

  if (res) {
    console.log("Debug ~ HandleGetDoctorOptions res")
    controller.setState({
     doctorOptionsLoading: false,
     doctorOptions: (res?.items || [])?.slice(0, 10),
     doctorOptionsController: null,
    });
  } else if (error && error !== "canceled") {
    console.log("Debug ~ HandleGetDoctorOptions error")
    controller.setState({
     doctorOptionsLoading: false,
     doctorOptions: [],
     doctorOptionsController: null,
    });
  } else if (res === null && !error) {
    console.log("Debug ~ HandleGetDoctorOptions cancel")
    // case cancel do noting
  }
 
};

export const HandleGetClinicOptions: Handler = async (controller, params) => {
  // const { divisionHasUser, selectedDivisionId, divisionIndexList } =
  //   controller.getState();
  // const data =
  //   (divisionHasUser || []).find(
  //     (item: any) => item.division_id === selectedDivisionId
  //   ) || null;
  // const divisionList = divisionIndexList || [];
  // const find = divisionIndexList?.find((item) => item.id === data.division_id);

  // if (!find) {
  //   divisionList.push({ id: data.division_id, name: data.division_name });
  // }

  // const filter = divisionList.filter((item) =>
  //   item.name?.toLowerCase().includes(params.search.toLowerCase())
  // );

  const [res, error, network] = await V3SearchProgramSpecialtyListView.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      limit: 10,
      search: params.search,
    },
    // ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
  });

  return [{ items: [] }, null];
};

export const HandleGetPostChart: Handler = async (controller, params) => {
  try {

    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json")
    }
    let config = firebaseConfig.find((item:any) => item.projectId === firebase_project)
  
    let result: any = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
    .child(`/ContentEngagement/post_engagement_${params.id}.json`)
    .getDownloadURL()
    .then( url => {
      return axios.get(url)
    })
  

    // const response = await controller.db
    //   .collection(POST_ENGAGEMENT)
    //   .where("id", "==", params.id)
    //   .get();

    controller.setState({
      marketingPostChart: result?.data || {},
    });
  } catch (error) {
    console.log("Error getting post content from firebase");
    controller.setState({
      marketingPostChart: {},
    });
  }
};

/* ------------------------ SAVE ------------------------ */
export const SaveMarketingPost: Handler = async (controller, params) => {
  const {
    marketingPostList,
    marketingPostIndex,
    selectedDivision,
    divisionHasUser,
    userProfile,
  } = controller.getState();

  let postList = [...(marketingPostList || [])];
  const promotionData =
    postList.find((item) => item.id === params.id) || ({} as PostData);
  const { id, ...data } = promotionData;

  delete data.extra_field;

  const banners_file = promotionData.extra_field?.banners_file;
  const image_files = promotionData.extra_field?.image_files;
  let content_files = promotionData.extra_field?.content_files;


  let today = moment().toDate();
  data.edit_date = today
  data.editor = `${userProfile.first_name || ""} ${userProfile.last_name || ""}`

  if (banners_file) {
    const params: any = {
      file: banners_file,
      bucketName: GS_BUCKET_NAME,
    };

    if (data.banners) {
      params.fileName = data.banners;
    }

    const response = await uploadFile(controller as any, params);
    data.banners = response.url;
  }

  if (!!image_files?.length) {
    const images = await updateImageFile(controller, { image_files });
    data.images = images;
  }

  if (!!content_files?.length) {
    const { contentFiles, description } = await updateContentFile(controller, {
      content_files,
      description: data.description,
    });

    data.description = description;
    content_files = contentFiles;
  }

  // if (data.clinic?.id) {
  //   updateDivisionIndex(controller, { division: data.clinic?.id });
  // }
  // if (data.hospital?.id) {
  //   updateOrganizationIndex(controller, { organization: data.hospital?.id });
  // }

  try {
    if (id) {
      // data.updated = today

      const postDivisionRef = doc(controller.db, POST_DIVISION, id);
      await setDoc(postDivisionRef, data, { merge: true });
      // Update
      // await controller.db
      //   .collection(POST_DIVISION)
      //   .doc(id)
      //   .set(data, { merge: true });

      if (typeof marketingPostIndex === "number") {
        postList[marketingPostIndex] = { id, ...data };
      }
      
      controller.setState({ successMessage: "Update post success." });
    } else {
      data.divisionId = selectedDivision?.division_id || null;
      data.divisionCode = selectedDivision?.division_code;
      data.organization =
        (divisionHasUser || []).find(
          (item: any) => item.division_code === data.divisionCode
        )?.division?.organization || null;

   
      data.edit_date = today
      data.created = today

      // New
      const docRef = await addDoc(collection(controller.db, POST_DIVISION), data);
      const newId = docRef.id

      CreateEngagement(controller as any, {
        collection: POST_ENGAGEMENT,
        contentId: newId,
      });

      postList[0].id = newId;

      controller.setState({
        successMessage: "Add post success.",
        marketingPostIndex: 0,
        marketingPostId: newId,
      });
    }
    GetListMarketingPost(controller, {});
  } catch (error) {
    controller.setState({ errorMessage: error });
  }

  if (params.callback) {
    params.callback({ content_files });
  }

  controller.setState({
    marketingPostList: postList,
  });
};

export const CreateEngagement: Handler = async (
  controller,
  params: { collection: string; contentId: string }
) => {

  await setDoc(doc(controller.db, params.collection, params.contentId), {
    id: params.contentId,
  });
  
  // await controller.db.collection(params.collection).doc(params.contentId).set({
  //   id: params.contentId,
  // });

  let firebase_project = CONFIG.FIREBASE_PROJECT;
  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );


  let jsonString = JSON.stringify({id: params.contentId})

  const blob = new Blob([jsonString], { type: "application/json" });
  await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/feed/dbjson/post_engagement_1NScHJpgghLzbpoeW5eI.json')
    .child(
      params.collection === "PostEngagement"
        ? `/ContentEngagement/post_engagement_${params.contentId}.json`
        : `/ContentEngagement/promotion_engagement_${params.contentId}.json`
    )
    .put(blob)

};

/* ------------------------- SET ------------------------ */
export const HandleSetMarketingPostIndex: Handler = (controller, params) => {
  HandleSetMarketingIndex(controller, {
    listKey: "marketingPostList",
    indexKey: "marketingPostIndex",
    idKey: "marketingPostId",
    index: params.index,
  });
};

export const HandleSetMarketingIndex: Handler = (
  controller,
  params: {
    listKey: string;
    indexKey: string;
    idKey: string;
    index: number;
  }
) => {
  const marketingList = (controller.getState() as any)[params.listKey];

  let list = marketingList || [];

  if (list?.[0]?.id === null) {
    const filter = (list || []).filter((item: any) => item.id !== null);
    list = filter;
    controller.setState({ [params.listKey]: filter });
  }

  controller.setState({
    [params.indexKey]: params.index,
    [params.idKey]: list?.[params.index]?.id || null,
  });
};

export const HandleChangeMarketingPost: Handler = (controller, params) => {
  const { marketingPostList, marketingPostIndex } = controller.getState();
  console.log("saika ~ marketingPostList:", marketingPostList)
  const cloneArray = [...(marketingPostList || [])];

  if (typeof marketingPostIndex === "number") {
    cloneArray[marketingPostIndex] = {
      ...cloneArray[marketingPostIndex],
      ...params.data,
    };
  }

  controller.setState({ marketingPostList: cloneArray });
};

export const HandleAddNewMarketingPost: Handler = (controller, params) => {
  const {
    marketingPostIndex,
    marketingPostList,
    divisionHasUser,
    selectedDivisionId,
  } = controller.getState();

  const firstList = marketingPostList?.[marketingPostIndex || 0];

  if (firstList?.id === null) {
    return;
  }

  const division =
    (divisionHasUser || []).find(
      (item: any) => item.division_id === selectedDivisionId
    ) || {};

  const list: PostData[] = [
    {
      id: null,
      title: "",
      type: "article",
      banners: null,
      description: "",
      tags: "",
      segment: [],
      hospital: {
        code: division?.organization?.code,
        name: division?.organization?.name,
        name_en: division?.organization?.name_en,
      },
      clinic: {
        code: division.division_code,
        name: division.division_name,
        name_en: division.division_name_en,
      },
      doctor: {
        id: null,
        full_name: "",
        code: null,
      },
      is_appointment: false,
      active: false,
      delete: false,
      url: null,
      short_description: "",
      divisionId: null,
      divisionCode: null,
      images: [],
      youtube_url: null,
      posting_date: null,
      manage_segment: [],
      organization: null,
      editor: null,
      edit_date: null,
    },
    ...(marketingPostList || []),
  ];

  controller.setState({
    marketingPostList: list,
    marketingPostIndex: 0,
    marketingPostId: null,
  });
};

export const HandleSubmitUrl: Handler = async (
  controller,
  params: Extract<Event, { message: "HandleSubmitUrl" }>["params"]
) => {
  const data: any = {
    url: params.link,
    description: "",
    title: params.title,
    banners: "",
  };

  if (params.link) {
    const result = await GetContentGenPreview(controller as any, params);

    for (const item of result.data.items) {
      if (item.tag === "title") {
        if (!data.title) {
          data.title = item.data;
        }
      } else if (item.tag === "img") {
        // data.banners = item.data;
      } else if (item.tag === "meta") {
        if (item.property === "og:title") {
        } else if (item.property === "og:image") {
          data.banners = item.content;
        } else if (item.property === "og:description") {
          data.description = item.content;
        }
      }
    }
  }

  if (params.callback) {
    params.callback();
  }

  controller.handleEvent({
    message: "HandleChangeMarketingPost",
    params: { data },
  });
};

// Utils
const updateDivisionIndex: Handler = async (controller, params) => {
  const { divisionHasUser, divisionIndexList } = controller.getState();
  const division =
    divisionHasUser?.find(
      (item: any) => item.division_id === params.division
    ) || {};


  const divisionRef = collection(controller.db, DIVISION_INDEX);

  // Create a query against the collection.
  const q = query(divisionRef, where("code", "==", division?.division_code || ""));
  const querySnapshot = await getDocs(q);

  // const divisionRef = controller.db.collection(DIVISION_INDEX);
  // const q = await controller.db
  //   .collection(DIVISION_INDEX)
  //   .where("code", "==", division?.division_code || "")
  //   .get();


  if (!querySnapshot?.docs.length) {
    const data = {
      id: params.division,
      code: division.division_code,
    };

    await addDoc(divisionRef, data);
    // await divisionRef.add(data);

    controller.setState({
      divisionIndexList: [
        { ...data, name: division.division_name },
        ...(divisionIndexList || []),
      ],
    });
  } else {
    // compare and update division if not equal
  }
};

export const updateOrganizationIndex: Handler = async (controller, params) => {
  const { divisionHasUser } = controller.getState();
  const division =
    divisionHasUser?.find(
      (item: any) => item.organization.id === params.organization
    ) || {};

  const organizationRef = collection(controller.db, ORGANIZATION_INDEX);
  const q = query(organizationRef, where("code", "==", division.organization?.code || ""));
  const querySnapshot = await getDocs(q);

    
  // const divisionRef = controller.db.collection(ORGANIZATION_INDEX);
  // const q = await controller.db
  //   .collection(ORGANIZATION_INDEX)
  //   .where("code", "==", division.organization?.code || "")
  //   .get();

  if (!querySnapshot?.docs.length && division.organization.code) {
    const data = {
      id: params.organization,
      code: division.organization.code,
    };

    await addDoc(organizationRef, data);
    // await divisionRef.add(data);

    controller.setState({
      divisionIndexList: [
        { ...data, name: division.organization.name },
        // ...(organizationIndexList || []),
      ],
    });
  } else {
    // compare and update division if not equal
  }
};

const updateImageFile: Handler = async (
  controller,
  params: { image_files: ImageFilesType[] }
) => {
  const { deleteArray, uploadArray, activeArray } = params.image_files.reduce(
    (result, item) => {
      if (!item.active && !item.file) {
        result.deleteArray.push(item);
      }

      if (item.active && item.file) {
        result.uploadArray.push(item);
      }

      if (item.active) {
        result.activeArray.push(item);
      }

      return result;
    },
    { deleteArray: [], uploadArray: [], activeArray: [] } as {
      deleteArray: any[];
      uploadArray: any[];
      activeArray: any[];
    }
  );

  deleteArray.forEach((item) => {
    deleteFile(controller as any, {
      url: item.image,
      bucketName: GS_BUCKET_NAME,
    });
  });

  const promiseArr = uploadArray.map((item) => {
    const params: any = {
      file: item.file,
      bucketName: GS_BUCKET_NAME,
      key: item.image,
    };

    return uploadFile(controller as any, params);
  });

  let response: any[] = [];

  if (promiseArr?.[0]) {
    response = await Promise.all(promiseArr);
  }

  return activeArray
    .map((item) => ({
      ...item,
      image: response.find((acc) => acc.key === item.image)?.url || item.image,
    }))
    .map((item) => item.image);
};

const updateContentFile: Handler = async (
  controller,
  params: { content_files: ContentFileType[]; description: string }
) => {
  let { content_files, description } = params;

  const filterDelete = content_files.filter((item) => item.status === "delete");
  let deleteArray = filterDelete
    .map((item) => {
      const index = content_files.findIndex(
        (acc) =>
          acc.index === item.index && acc.status === "create" && !acc.file
      );
      if (index !== -1) {
        return {
          ...content_files[index],
          index,
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  deleteArray = deleteArray.filter(
    (item) =>
      deleteArray.filter((acc) => acc?.src === item?.src).length ===
      content_files.filter((acc) => acc?.src === item?.src).length
  );

  const deleteIndex = deleteArray.map((item) => item?.index);

  deleteArray.forEach((item) => {
    deleteFile(controller as any, {
      url: item?.src,
      bucketName: GS_BUCKET_NAME,
    });
  });

  const [uploadArray, uploadIndex] = content_files.reduce(
    (result, item, index) => {
      const check =
        item.file && !filterDelete.find((acc) => acc.index === item.index);
      if (!!check) {
        result[0].push(content_files[index]);
        result[1].push(index);
      }
      return result;
    },
    [[] as any[], [] as number[]]
  );

  const promiseArr = uploadArray.map((item: any) => {
    const params: any = {
      file: item.file,
      bucketName: GS_BUCKET_NAME,
      key: item.src,
    };

    return uploadFile(controller as any, params);
  });

  let response: any[] = [];

  if (promiseArr?.[0]) {
    response = await Promise.all(promiseArr);
  }

  response.forEach((res) => {
    const reg = new RegExp(
      res.key.replace(/\//g, "\\/").replace(/\+/g, "\\+"),
      "g"
    );
    description = description.replace(reg, res.url);
  });

  console.log("removeIndex", uploadIndex, deleteIndex, content_files, response);

  const removeIndex = [...uploadIndex, ...deleteIndex];
  content_files = content_files.filter(
    (_, index) => !removeIndex.includes(index)
  );

  return {
    contentFiles: content_files,
    description,
  };
};

const detectLanguage = (text: string): string => {
  // Regular expression สำหรับตรวจจับตัวอักษรภาษาไทย
  const thaiPattern = /[\u0E00-\u0E7F]/;

  // ตรวจสอบว่ามีตัวอักษรภาษาไทยในข้อความหรือไม่
  if (thaiPattern.test(text)) {
    return 'th-TH';
  }

  // หากไม่พบตัวอักษรภาษาไทย ให้ถือว่าเป็นภาษาอังกฤษ
  return 'en-US';
};
